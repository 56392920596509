var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "show-img", on: { mouseup: (e) => _vm.characterUp(e) } },
    [
      _c("div", { staticClass: "show-img-content" }, [
        _c(
          "div",
          {
            staticClass: "show-img-top",
            on: { mousemove: (e) => _vm.disMovePage(e) },
          },
          [
            _c("div", { staticClass: "show-img-top-border" }),
            _c("div", { style: { backgroundColor: _vm.backgroundColor } }, [
              _c("div", { staticClass: "show-img-top-left" }),
              _c(
                "div",
                { staticClass: "show-img-top-center" },
                _vm._l(_vm.showOptions.leftOptions, function (option) {
                  return _c(
                    "span",
                    {
                      key: option.id,
                      class: option.checked
                        ? "show-option show-option-select"
                        : "show-option",
                      on: {
                        click: function ($event) {
                          return _vm.changeLeftOption(option.value)
                        },
                      },
                    },
                    [_vm._v(_vm._s(option.title))]
                  )
                }),
                0
              ),
              _c("div", { staticClass: "show-img-top-right" }),
            ]),
            _c("div", { style: { backgroundColor: _vm.backgroundColor } }, [
              _c("div", { staticClass: "show-img-top-left" }),
              _c(
                "div",
                { staticClass: "show-img-top-center" },
                _vm._l(_vm.showOptions.rightOptions, function (option) {
                  return _c(
                    "span",
                    {
                      key: option.id,
                      class: option.checked
                        ? "show-option show-option-select"
                        : "show-option",
                      on: {
                        click: function ($event) {
                          return _vm.changeRightOption(option.value)
                        },
                      },
                    },
                    [_vm._v(_vm._s(option.title))]
                  )
                }),
                0
              ),
              _c("div", { staticClass: "show-img-top-right" }, [
                _c(
                  "div",
                  [_c("a-icon", { attrs: { type: "double-right" } })],
                  1
                ),
              ]),
            ]),
          ]
        ),
        _c(
          "div",
          {
            class: _vm.isAddText
              ? "show-img-bottom show-img-bottom-textArea"
              : _vm.isAddLine
              ? "show-img-bottom show-img-bottom-line"
              : _vm.isAddAnnotation
              ? "show-img-bottom show-img-bottom-local"
              : "show-img-bottom",
            style: { backgroundColor: _vm.backgroundColor },
            on: {
              scroll: _vm.showImgScroll,
              click: function ($event) {
                $event.stopPropagation()
                return _vm.showImgClick.apply(null, arguments)
              },
              mouseup: _vm.textAreaMouseup,
              mousemove: (e) => _vm.disMovePage(e),
              mouseover: _vm.topCenterMouseout,
            },
          },
          [
            _c(
              "a-dropdown",
              { attrs: { trigger: ["contextmenu"] } },
              [
                _c("div", { staticClass: "show-img-bottom-left" }, [
                  _c(
                    "div",
                    {
                      style: {
                        minHeight: "100%",
                        backgroundColor: _vm.backgroundColor,
                      },
                    },
                    [
                      _vm.checkPermission("showImgLeft:view")
                        ? _c(
                            "div",
                            {
                              style: {
                                minHeight: "100%",
                                width: _vm.showImgListWidth,
                              },
                              on: {
                                contextmenu: function ($event) {
                                  $event.stopPropagation()
                                },
                              },
                            },
                            [
                              _vm.showOptions.leftOptions[0].checked
                                ? [
                                    _vm._l(
                                      _vm.pageListData,
                                      function (item, idx) {
                                        return _c("img", {
                                          key: item.id,
                                          ref: item.id + "showLeftImg",
                                          refInFor: true,
                                          class: _vm.imgShow(
                                            item,
                                            _vm.showImgLeftListPostions[idx] ||
                                              _vm.showImgLeftListPostions[0]
                                          )
                                            ? "left-show-img"
                                            : "left-show-img left-show-img-blur",
                                          style:
                                            "width:100%;box-siz;height:auto;border-bottom:2px dashed " +
                                            (idx == _vm.pageListData.length - 1
                                              ? "rgba(0,0,0,0)"
                                              : "orange"),
                                          attrs: {
                                            src: item.filePath,
                                            alt: "",
                                          },
                                          on: {
                                            dragstart: function ($event) {
                                              $event.preventDefault()
                                            },
                                          },
                                        })
                                      }
                                    ),
                                    _vm.pageListData.length &&
                                    _vm.showImgLeftListPostions.length
                                      ? _c(
                                          "div",
                                          {
                                            ref: "dragTextBox",
                                            staticClass:
                                              "ocr-show ocr-show-left",
                                            on: {
                                              click: _vm.ocrShowClick,
                                              mousedown: _vm.dragBoxmousedown,
                                            },
                                          },
                                          [
                                            _vm.is_show_text_mask
                                              ? _c("div", {
                                                  staticClass: "text_mask",
                                                  style:
                                                    "width:" +
                                                    _vm.mask_width +
                                                    "px;left:" +
                                                    _vm.mask_left +
                                                    "px;height:" +
                                                    _vm.mask_height +
                                                    "px;top:" +
                                                    _vm.mask_top +
                                                    "px;",
                                                })
                                              : _vm._e(),
                                            _vm._l(
                                              _vm.pageListData,
                                              function (page, idx) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: page.id,
                                                    staticClass:
                                                      "ocr-show-page",
                                                    style: {
                                                      height:
                                                        _vm
                                                          .showImgLeftListPostions[
                                                          idx
                                                        ] &&
                                                        _vm
                                                          .showImgLeftListPostions[
                                                          idx
                                                        ].height + "px",
                                                      overflow: _vm.rightImgShow
                                                        ? "initial"
                                                        : "hidden",
                                                    },
                                                    attrs: {
                                                      id:
                                                        page.id +
                                                        "-left-" +
                                                        idx,
                                                    },
                                                    on: {
                                                      mousedown: function (
                                                        $event
                                                      ) {
                                                        if (
                                                          $event.target !==
                                                          $event.currentTarget
                                                        )
                                                          return null
                                                        return _vm.clearSelect.apply(
                                                          null,
                                                          arguments
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm.selectTextAreas.length >
                                                      1 &&
                                                    _vm.getDeleteBorder.idx ===
                                                      idx
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "delete-border",
                                                            style:
                                                              _vm.getDeleteBorder,
                                                            on: {
                                                              mousedown:
                                                                function (
                                                                  $event
                                                                ) {
                                                                  if (
                                                                    $event.target !==
                                                                    $event.currentTarget
                                                                  )
                                                                    return null
                                                                  return _vm.clearSelect.apply(
                                                                    null,
                                                                    arguments
                                                                  )
                                                                },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              [
                                                                _c("a-icon", {
                                                                  attrs: {
                                                                    type: "close",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        $event.stopPropagation()
                                                                        return _vm.batchDeleteTextArea.apply(
                                                                          null,
                                                                          arguments
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _vm._l(
                                                      page.textAreas,
                                                      function (item) {
                                                        return [
                                                          (_vm.textShow(
                                                            item,
                                                            _vm
                                                              .showImgLeftListPostions[
                                                              idx
                                                            ] ||
                                                              _vm
                                                                .showImgLeftListPostions[0],
                                                            page
                                                          ) ||
                                                            idx ==
                                                              _vm.pageListData
                                                                .length -
                                                                1) &&
                                                          (
                                                            item.id + ""
                                                          ).indexOf("new") ==
                                                            -1 &&
                                                          _vm.leftImgShow &&
                                                          !item.imgUrl
                                                            ? _c(
                                                                "vue-draggable-resizable",
                                                                {
                                                                  key: item.id,
                                                                  ref:
                                                                    "draggable-" +
                                                                    item.id,
                                                                  refInFor: true,
                                                                  staticClass:
                                                                    "ocr-show-page-text",
                                                                  style: `position: absolute; border: none;min-width:${
                                                                    _vm
                                                                      .showImgLeftListPostions[
                                                                      idx
                                                                    ]
                                                                      ? (_vm
                                                                          .showImgLeftListPostions[
                                                                          idx
                                                                        ]
                                                                          .width /
                                                                          page.width) *
                                                                        80
                                                                      : "0"
                                                                  }px;min-height:${
                                                                    _vm
                                                                      .showImgLeftListPostions[
                                                                      idx
                                                                    ]
                                                                      ? (_vm
                                                                          .showImgLeftListPostions[
                                                                          idx
                                                                        ]
                                                                          .width /
                                                                          page.width) *
                                                                        60
                                                                      : "0"
                                                                  }px`,
                                                                  attrs: {
                                                                    "v-model:active":
                                                                      item
                                                                        .textAreaRegion
                                                                        .selected
                                                                        ? true
                                                                        : false,
                                                                    parent: true,
                                                                    draggable:
                                                                      item
                                                                        .translateTextAreaRegion
                                                                        .editble ||
                                                                      _vm.isCheckText ||
                                                                      _vm.isDelete
                                                                        ? false
                                                                        : _vm.checkPermission(
                                                                            "addTextArea:edit"
                                                                          ) &&
                                                                          _vm.changeAll &&
                                                                          item
                                                                            .textAreaRegion
                                                                            .selected
                                                                        ? true
                                                                        : false,
                                                                    resizable:
                                                                      _vm.checkPermission(
                                                                        "addTextArea:edit"
                                                                      ) &&
                                                                      _vm.taskStatus ==
                                                                        "可开始"
                                                                        ? true
                                                                        : false,
                                                                    x: _vm.getItemStyle(
                                                                      item,
                                                                      _vm
                                                                        .showImgLeftListPostions[
                                                                        idx
                                                                      ] ||
                                                                        _vm
                                                                          .showImgLeftListPostions[0],
                                                                      page,
                                                                      "left"
                                                                    ).x,
                                                                    y: _vm.getItemStyle(
                                                                      item,
                                                                      _vm
                                                                        .showImgLeftListPostions[
                                                                        idx
                                                                      ] ||
                                                                        _vm
                                                                          .showImgLeftListPostions[0],
                                                                      page,
                                                                      "left"
                                                                    ).y,
                                                                    h: _vm.getItemStyle(
                                                                      item,
                                                                      _vm
                                                                        .showImgLeftListPostions[
                                                                        idx
                                                                      ] ||
                                                                        _vm
                                                                          .showImgLeftListPostions[0],
                                                                      page,
                                                                      "left"
                                                                    ).h,
                                                                    w: _vm.getItemStyle(
                                                                      item,
                                                                      _vm
                                                                        .showImgLeftListPostions[
                                                                        idx
                                                                      ] ||
                                                                        _vm
                                                                          .showImgLeftListPostions[0],
                                                                      page,
                                                                      "left"
                                                                    ).w,
                                                                    minW: 100,
                                                                    z: item
                                                                      .textAreaRegion
                                                                      .selected
                                                                      ? 99
                                                                      : 97,
                                                                  },
                                                                  on: {
                                                                    activated:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.onActivated(
                                                                          item,
                                                                          "left",
                                                                          page
                                                                        )
                                                                      },
                                                                    deactivated:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.onDeactivated(
                                                                          item,
                                                                          "left",
                                                                          page
                                                                        )
                                                                      },
                                                                    resizing: (
                                                                      left,
                                                                      top,
                                                                      width,
                                                                      height
                                                                    ) => {
                                                                      _vm.onResizing(
                                                                        left,
                                                                        top,
                                                                        width,
                                                                        height,
                                                                        item,
                                                                        page,
                                                                        _vm
                                                                          .showImgLeftListPostions[
                                                                          idx
                                                                        ]
                                                                      )
                                                                    },
                                                                    resizestop:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.onResizstop(
                                                                          item,
                                                                          "left",
                                                                          page
                                                                        )
                                                                      },
                                                                    dragstop: (
                                                                      left,
                                                                      top
                                                                    ) =>
                                                                      _vm.onDragstop(
                                                                        left,
                                                                        top,
                                                                        item,
                                                                        page,
                                                                        _vm
                                                                          .showImgLeftListPostions[
                                                                          idx
                                                                        ],
                                                                        "left"
                                                                      ),
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      class:
                                                                        item
                                                                          .textAreaRegion
                                                                          .selected
                                                                          ? "ocr-show-item-selected ocr-show-item"
                                                                          : item.isReject
                                                                          ? "ocr-show-item ocr-show-item-reject"
                                                                          : "ocr-show-item",
                                                                      attrs: {
                                                                        id:
                                                                          page.id +
                                                                          "-" +
                                                                          item.id +
                                                                          "-" +
                                                                          "left",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            $event.stopPropagation()
                                                                          },
                                                                        mousedown:
                                                                          _vm.textAreaMousedown,
                                                                        mouseup:
                                                                          _vm.textAreaMouseup,
                                                                        mousemove:
                                                                          (e) =>
                                                                            _vm.leftMove(
                                                                              e,
                                                                              item
                                                                            ),
                                                                        mouseleave:
                                                                          (e) =>
                                                                            _vm.leftOut(
                                                                              e,
                                                                              item
                                                                            ),
                                                                      },
                                                                    },
                                                                    [
                                                                      item
                                                                        .textAreaRegion
                                                                        .selected
                                                                        ? _c(
                                                                            "div",
                                                                            {
                                                                              staticStyle:
                                                                                {
                                                                                  "user-select":
                                                                                    "text",
                                                                                },
                                                                              on: {
                                                                                mousemove:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    _vm.isCheckText = true
                                                                                  },
                                                                                mouseout:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    _vm.isCheckText = false
                                                                                  },
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    $event.stopPropagation()
                                                                                  },
                                                                              },
                                                                            },
                                                                            [
                                                                              _vm
                                                                                .select_list
                                                                                .length ==
                                                                              1
                                                                                ? _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "ocr-show-item-action",
                                                                                      style:
                                                                                        _vm.getActionStyle(
                                                                                          _vm
                                                                                            .showImgLeftListPostions[
                                                                                            idx
                                                                                          ],
                                                                                          page,
                                                                                          item,
                                                                                          "left",
                                                                                          idx
                                                                                        ),
                                                                                      on: {
                                                                                        mouseenter:
                                                                                          (
                                                                                            e
                                                                                          ) =>
                                                                                            _vm.leftOut(
                                                                                              e,
                                                                                              item
                                                                                            ),
                                                                                        mousemove:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            $event.stopPropagation()
                                                                                            _vm.isCheckText = true
                                                                                          },
                                                                                      },
                                                                                    },
                                                                                    [
                                                                                      _vm.changeAll
                                                                                        ? _c(
                                                                                            "div",
                                                                                            {
                                                                                              staticClass:
                                                                                                "ocr-show-item-action-top",
                                                                                            },
                                                                                            [
                                                                                              _vm
                                                                                                .taskDetail
                                                                                                .beingTested &&
                                                                                              !_vm
                                                                                                .taskDetail
                                                                                                .rejectTaskId
                                                                                                ? _c(
                                                                                                    "div",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "ocr-show-item-action-radio",
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        "span",
                                                                                                        {
                                                                                                          class:
                                                                                                            _vm.selectErrorType ==
                                                                                                            3
                                                                                                              ? "error-span"
                                                                                                              : "no-error-span",
                                                                                                          style:
                                                                                                            _vm
                                                                                                              .taskDetail
                                                                                                              .stageName ==
                                                                                                              "翻译" ||
                                                                                                            !_vm.changeAll ||
                                                                                                            _vm.isRejectStatus
                                                                                                              ? "color: red; margin-left: 3px;"
                                                                                                              : "color: red; margin-left: 3px",
                                                                                                          on: {
                                                                                                            mousedown:
                                                                                                              function (
                                                                                                                $event
                                                                                                              ) {
                                                                                                                $event.stopPropagation()
                                                                                                                return _vm.changeInjuryType(
                                                                                                                  3,
                                                                                                                  item
                                                                                                                )
                                                                                                              },
                                                                                                          },
                                                                                                        },
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            "错"
                                                                                                          ),
                                                                                                          _vm.selectErrorType ==
                                                                                                          3
                                                                                                            ? _c(
                                                                                                                "a-icon",
                                                                                                                {
                                                                                                                  attrs:
                                                                                                                    {
                                                                                                                      type: "check-circle",
                                                                                                                      theme:
                                                                                                                        "filled",
                                                                                                                    },
                                                                                                                }
                                                                                                              )
                                                                                                            : _vm._e(),
                                                                                                        ],
                                                                                                        1
                                                                                                      ),
                                                                                                      _c(
                                                                                                        "span",
                                                                                                        {
                                                                                                          class:
                                                                                                            _vm.selectErrorType ==
                                                                                                            2
                                                                                                              ? "error-span"
                                                                                                              : "no-error-span",
                                                                                                          staticStyle:
                                                                                                            {
                                                                                                              color:
                                                                                                                "red",
                                                                                                              "margin-left":
                                                                                                                "3px",
                                                                                                            },
                                                                                                          style:
                                                                                                            _vm
                                                                                                              .taskDetail
                                                                                                              .stageName ==
                                                                                                              "翻译" ||
                                                                                                            !_vm.changeAll ||
                                                                                                            _vm.isRejectStatus
                                                                                                              ? "color: red; margin-left: 3px;"
                                                                                                              : "color: red; margin-left: 3px",
                                                                                                          on: {
                                                                                                            mousedown:
                                                                                                              function (
                                                                                                                $event
                                                                                                              ) {
                                                                                                                $event.stopPropagation()
                                                                                                                return _vm.changeInjuryType(
                                                                                                                  2,
                                                                                                                  item
                                                                                                                )
                                                                                                              },
                                                                                                          },
                                                                                                        },
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            "硬"
                                                                                                          ),
                                                                                                          _vm.selectErrorType ==
                                                                                                          2
                                                                                                            ? _c(
                                                                                                                "a-icon",
                                                                                                                {
                                                                                                                  attrs:
                                                                                                                    {
                                                                                                                      type: "check-circle",
                                                                                                                      theme:
                                                                                                                        "filled",
                                                                                                                    },
                                                                                                                }
                                                                                                              )
                                                                                                            : _vm._e(),
                                                                                                        ],
                                                                                                        1
                                                                                                      ),
                                                                                                      _c(
                                                                                                        "span",
                                                                                                        {
                                                                                                          class:
                                                                                                            _vm.selectErrorType ==
                                                                                                            1
                                                                                                              ? "error-span"
                                                                                                              : "no-error-span",
                                                                                                          style:
                                                                                                            _vm
                                                                                                              .taskDetail
                                                                                                              .stageName ==
                                                                                                              "翻译" ||
                                                                                                            !_vm.changeAll ||
                                                                                                            _vm.isRejectStatus
                                                                                                              ? "color: red; margin-left: 3px;"
                                                                                                              : "color: red; margin-left: 3px",
                                                                                                          on: {
                                                                                                            mousedown:
                                                                                                              function (
                                                                                                                $event
                                                                                                              ) {
                                                                                                                $event.stopPropagation()
                                                                                                                return _vm.changeInjuryType(
                                                                                                                  1,
                                                                                                                  item
                                                                                                                )
                                                                                                              },
                                                                                                          },
                                                                                                        },
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            "软"
                                                                                                          ),
                                                                                                          _vm.selectErrorType ==
                                                                                                          1
                                                                                                            ? _c(
                                                                                                                "a-icon",
                                                                                                                {
                                                                                                                  attrs:
                                                                                                                    {
                                                                                                                      type: "check-circle",
                                                                                                                      theme:
                                                                                                                        "filled",
                                                                                                                    },
                                                                                                                }
                                                                                                              )
                                                                                                            : _vm._e(),
                                                                                                        ],
                                                                                                        1
                                                                                                      ),
                                                                                                      _c(
                                                                                                        "span",
                                                                                                        {
                                                                                                          class:
                                                                                                            _vm.selectErrorType ==
                                                                                                            4
                                                                                                              ? "error-span"
                                                                                                              : "no-error-span",
                                                                                                          style:
                                                                                                            _vm
                                                                                                              .taskDetail
                                                                                                              .stageName ==
                                                                                                              "翻译" ||
                                                                                                            !_vm.changeAll ||
                                                                                                            _vm.isRejectStatus
                                                                                                              ? "color: orange; margin-left: 3px;"
                                                                                                              : "color: orange; margin-left: 3px",
                                                                                                          on: {
                                                                                                            mousedown:
                                                                                                              function (
                                                                                                                $event
                                                                                                              ) {
                                                                                                                $event.stopPropagation()
                                                                                                                return _vm.changeInjuryType(
                                                                                                                  4,
                                                                                                                  item
                                                                                                                )
                                                                                                              },
                                                                                                          },
                                                                                                        },
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            "驳"
                                                                                                          ),
                                                                                                          _vm.selectErrorType ==
                                                                                                          4
                                                                                                            ? _c(
                                                                                                                "a-icon",
                                                                                                                {
                                                                                                                  attrs:
                                                                                                                    {
                                                                                                                      type: "check-circle",
                                                                                                                      theme:
                                                                                                                        "filled",
                                                                                                                    },
                                                                                                                }
                                                                                                              )
                                                                                                            : _vm._e(),
                                                                                                        ],
                                                                                                        1
                                                                                                      ),
                                                                                                    ]
                                                                                                  )
                                                                                                : _vm._e(),
                                                                                              _c(
                                                                                                "div",
                                                                                                {
                                                                                                  staticStyle:
                                                                                                    {
                                                                                                      display:
                                                                                                        "flex",
                                                                                                    },
                                                                                                },
                                                                                                [
                                                                                                  _vm
                                                                                                    .taskDetail
                                                                                                    .targetLang ==
                                                                                                    "JAPANESE" &&
                                                                                                  ((_vm
                                                                                                    .taskDetail
                                                                                                    .taskStatus ==
                                                                                                    "驳回" &&
                                                                                                    _vm
                                                                                                      .taskDetail
                                                                                                      .rejectTaskId &&
                                                                                                    item.isReject) ||
                                                                                                    _vm
                                                                                                      .taskDetail
                                                                                                      .taskStatus ==
                                                                                                      "可开始")
                                                                                                    ? _c(
                                                                                                        "div",
                                                                                                        {
                                                                                                          staticClass:
                                                                                                            "check-phonetic",
                                                                                                        },
                                                                                                        [
                                                                                                          _c(
                                                                                                            "a",
                                                                                                            {
                                                                                                              on: {
                                                                                                                click:
                                                                                                                  function (
                                                                                                                    $event
                                                                                                                  ) {
                                                                                                                    return _vm.checkPhonetic(
                                                                                                                      item
                                                                                                                    )
                                                                                                                  },
                                                                                                              },
                                                                                                            },
                                                                                                            [
                                                                                                              _vm._v(
                                                                                                                "检测注音"
                                                                                                              ),
                                                                                                            ]
                                                                                                          ),
                                                                                                        ]
                                                                                                      )
                                                                                                    : _vm._e(),
                                                                                                  _vm
                                                                                                    .taskDetail
                                                                                                    .isMachineTranslation
                                                                                                    ? _c(
                                                                                                        "div",
                                                                                                        {
                                                                                                          staticClass:
                                                                                                            "check-phonetic",
                                                                                                        },
                                                                                                        [
                                                                                                          _c(
                                                                                                            "a-spin",
                                                                                                            {
                                                                                                              attrs:
                                                                                                                {
                                                                                                                  spinning:
                                                                                                                    _vm.ocrLoading,
                                                                                                                },
                                                                                                            },
                                                                                                            [
                                                                                                              _c(
                                                                                                                "a-icon",
                                                                                                                {
                                                                                                                  staticStyle:
                                                                                                                    {
                                                                                                                      "font-size":
                                                                                                                        "24px",
                                                                                                                    },
                                                                                                                  attrs:
                                                                                                                    {
                                                                                                                      slot: "indicator",
                                                                                                                      type: "loading",
                                                                                                                      spin: "",
                                                                                                                    },
                                                                                                                  slot: "indicator",
                                                                                                                }
                                                                                                              ),
                                                                                                              _c(
                                                                                                                "a",
                                                                                                                {
                                                                                                                  on: {
                                                                                                                    click:
                                                                                                                      function (
                                                                                                                        $event
                                                                                                                      ) {
                                                                                                                        return _vm.changeTranslate(
                                                                                                                          item
                                                                                                                        )
                                                                                                                      },
                                                                                                                  },
                                                                                                                },
                                                                                                                [
                                                                                                                  _vm._v(
                                                                                                                    "切换机翻"
                                                                                                                  ),
                                                                                                                ]
                                                                                                              ),
                                                                                                            ],
                                                                                                            1
                                                                                                          ),
                                                                                                        ],
                                                                                                        1
                                                                                                      )
                                                                                                    : _vm._e(),
                                                                                                  _c(
                                                                                                    "div",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "check-phonetic",
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        "a-spin",
                                                                                                        {
                                                                                                          attrs:
                                                                                                            {
                                                                                                              spinning:
                                                                                                                _vm.ocrLoading,
                                                                                                            },
                                                                                                        },
                                                                                                        [
                                                                                                          _c(
                                                                                                            "a-icon",
                                                                                                            {
                                                                                                              staticStyle:
                                                                                                                {
                                                                                                                  "font-size":
                                                                                                                    "24px",
                                                                                                                },
                                                                                                              attrs:
                                                                                                                {
                                                                                                                  slot: "indicator",
                                                                                                                  type: "loading",
                                                                                                                  spin: "",
                                                                                                                },
                                                                                                              slot: "indicator",
                                                                                                            }
                                                                                                          ),
                                                                                                          _c(
                                                                                                            "a",
                                                                                                            {
                                                                                                              on: {
                                                                                                                click:
                                                                                                                  function (
                                                                                                                    $event
                                                                                                                  ) {
                                                                                                                    return _vm.ocr(
                                                                                                                      item
                                                                                                                    )
                                                                                                                  },
                                                                                                              },
                                                                                                            },
                                                                                                            [
                                                                                                              _vm._v(
                                                                                                                "识别文字"
                                                                                                              ),
                                                                                                            ]
                                                                                                          ),
                                                                                                        ],
                                                                                                        1
                                                                                                      ),
                                                                                                    ],
                                                                                                    1
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                            ]
                                                                                          )
                                                                                        : _vm._e(),
                                                                                      _vm.glossaryTags.filter(
                                                                                        (
                                                                                          w
                                                                                        ) =>
                                                                                          (item.originalText &&
                                                                                            (item.originalText
                                                                                              .replaceAll(
                                                                                                "\n",
                                                                                                ""
                                                                                              )
                                                                                              .indexOf(
                                                                                                w.value
                                                                                              ) >
                                                                                              -1 ||
                                                                                              w.value.indexOf(
                                                                                                item.originalText.replaceAll(
                                                                                                  "\n",
                                                                                                  ""
                                                                                                )
                                                                                              ) >
                                                                                                -1)) ||
                                                                                          (item.showText &&
                                                                                            (item.showText
                                                                                              .replaceAll(
                                                                                                "\n",
                                                                                                ""
                                                                                              )
                                                                                              .indexOf(
                                                                                                w.target
                                                                                              ) >
                                                                                              -1 ||
                                                                                              w.target.indexOf(
                                                                                                item.showText.replaceAll(
                                                                                                  "\n",
                                                                                                  ""
                                                                                                )
                                                                                              ) >
                                                                                                -1))
                                                                                      )
                                                                                        .length ||
                                                                                      _vm.terms.filter(
                                                                                        (
                                                                                          w
                                                                                        ) =>
                                                                                          (item.originalText &&
                                                                                            item
                                                                                              .originalText
                                                                                              .length >
                                                                                              0 &&
                                                                                            w.valueX &&
                                                                                            (item.originalText
                                                                                              .replaceAll(
                                                                                                "\n",
                                                                                                ""
                                                                                              )
                                                                                              .indexOf(
                                                                                                w.valueX
                                                                                              ) >
                                                                                              -1 ||
                                                                                              w.valueX.indexOf(
                                                                                                item.originalText.replaceAll(
                                                                                                  "\n",
                                                                                                  ""
                                                                                                )
                                                                                              ) >
                                                                                                -1)) ||
                                                                                          (item.originalText &&
                                                                                            item
                                                                                              .originalText
                                                                                              .length >
                                                                                              0 &&
                                                                                            w.valueM &&
                                                                                            (item.originalText
                                                                                              .replaceAll(
                                                                                                "\n",
                                                                                                ""
                                                                                              )
                                                                                              .indexOf(
                                                                                                w.valueM
                                                                                              ) >
                                                                                              -1 ||
                                                                                              w.valueM.indexOf(
                                                                                                item.originalText.replaceAll(
                                                                                                  "\n",
                                                                                                  ""
                                                                                                )
                                                                                              ) >
                                                                                                -1)) ||
                                                                                          (item.showText &&
                                                                                            item
                                                                                              .showText
                                                                                              .length >
                                                                                              0 &&
                                                                                            w.targetX &&
                                                                                            (item.showText
                                                                                              .replaceAll(
                                                                                                "\n",
                                                                                                ""
                                                                                              )
                                                                                              .indexOf(
                                                                                                w.targetX
                                                                                              ) >
                                                                                              -1 ||
                                                                                              w.targetX.indexOf(
                                                                                                item.showText.replaceAll(
                                                                                                  "\n",
                                                                                                  ""
                                                                                                )
                                                                                              ) >
                                                                                                -1)) ||
                                                                                          (item.showText &&
                                                                                            item
                                                                                              .showText
                                                                                              .length >
                                                                                              0 &&
                                                                                            w.targetM &&
                                                                                            (item.showText
                                                                                              .replaceAll(
                                                                                                "\n",
                                                                                                ""
                                                                                              )
                                                                                              .indexOf(
                                                                                                w.targetM
                                                                                              ) >
                                                                                              -1 ||
                                                                                              w.targetM.indexOf(
                                                                                                item.showText.replaceAll(
                                                                                                  "\n",
                                                                                                  ""
                                                                                                )
                                                                                              ) >
                                                                                                -1)) ||
                                                                                          (item.showText &&
                                                                                            item
                                                                                              .showText
                                                                                              .length >
                                                                                              0 &&
                                                                                            w.target &&
                                                                                            (item.showText
                                                                                              .replaceAll(
                                                                                                "\n",
                                                                                                ""
                                                                                              )
                                                                                              .indexOf(
                                                                                                w.target
                                                                                              ) >
                                                                                              -1 ||
                                                                                              w.target.indexOf(
                                                                                                item.showText.replaceAll(
                                                                                                  "\n",
                                                                                                  ""
                                                                                                )
                                                                                              ) >
                                                                                                -1)) ||
                                                                                          (item.originalText &&
                                                                                            item
                                                                                              .originalText
                                                                                              .length >
                                                                                              0 &&
                                                                                            w.value &&
                                                                                            (item.originalText
                                                                                              .replaceAll(
                                                                                                "\n",
                                                                                                ""
                                                                                              )
                                                                                              .indexOf(
                                                                                                w.value
                                                                                              ) >
                                                                                              -1 ||
                                                                                              w.value.indexOf(
                                                                                                item.originalText.replaceAll(
                                                                                                  "\n",
                                                                                                  ""
                                                                                                )
                                                                                              ) >
                                                                                                -1))
                                                                                      )
                                                                                        .length ||
                                                                                      _vm.termBacks.filter(
                                                                                        (
                                                                                          w
                                                                                        ) =>
                                                                                          (item.originalText &&
                                                                                            w.value &&
                                                                                            item
                                                                                              .originalText
                                                                                              .length >
                                                                                              0 &&
                                                                                            (item.originalText
                                                                                              .replaceAll(
                                                                                                "\n",
                                                                                                ""
                                                                                              )
                                                                                              .indexOf(
                                                                                                w.value
                                                                                              ) >
                                                                                              -1 ||
                                                                                              w.value.indexOf(
                                                                                                item.originalText.replaceAll(
                                                                                                  "\n",
                                                                                                  ""
                                                                                                )
                                                                                              ) >
                                                                                                -1)) ||
                                                                                          (item.showText &&
                                                                                            w.target &&
                                                                                            item
                                                                                              .showText
                                                                                              .length >
                                                                                              0 &&
                                                                                            (item.showText
                                                                                              .replaceAll(
                                                                                                "\n",
                                                                                                ""
                                                                                              )
                                                                                              .indexOf(
                                                                                                w.target
                                                                                              ) >
                                                                                              -1 ||
                                                                                              w.target.indexOf(
                                                                                                item.showText.replaceAll(
                                                                                                  "\n",
                                                                                                  ""
                                                                                                )
                                                                                              ) >
                                                                                                -1))
                                                                                      )
                                                                                        .length ||
                                                                                      _vm.termNotes.filter(
                                                                                        (
                                                                                          w
                                                                                        ) =>
                                                                                          w.value &&
                                                                                          item.showText
                                                                                            .replaceAll(
                                                                                              "\n",
                                                                                              ""
                                                                                            )
                                                                                            .indexOf(
                                                                                              w.value
                                                                                            ) >
                                                                                            -1
                                                                                      )
                                                                                        .length ||
                                                                                      (_vm
                                                                                        .yahooOrKdxList
                                                                                        .length &&
                                                                                        (_vm.yahooOrKdxList.some(
                                                                                          (
                                                                                            words
                                                                                          ) =>
                                                                                            _vm.getBlackWordsTwo(
                                                                                              item,
                                                                                              words
                                                                                            )
                                                                                              .length
                                                                                        ) ||
                                                                                          _vm.yahooOrKdxList.some(
                                                                                            (
                                                                                              words
                                                                                            ) =>
                                                                                              words.classId !=
                                                                                                12 &&
                                                                                              _vm.getBlackWords(
                                                                                                item,
                                                                                                words
                                                                                              )
                                                                                          ))) ||
                                                                                      _vm
                                                                                        .checkPhoneticList
                                                                                        .length ||
                                                                                      (_vm
                                                                                        .blacklist
                                                                                        .length &&
                                                                                        (_vm.blacklist.some(
                                                                                          (
                                                                                            words
                                                                                          ) =>
                                                                                            _vm.getBlackWordsTwo(
                                                                                              item,
                                                                                              words
                                                                                            )
                                                                                              .length
                                                                                        ) ||
                                                                                          _vm.blacklist.some(
                                                                                            (
                                                                                              words
                                                                                            ) =>
                                                                                              words.classId !=
                                                                                                12 &&
                                                                                              _vm.getBlackWords(
                                                                                                item,
                                                                                                words
                                                                                              )
                                                                                          ))) ||
                                                                                      _vm.phoneticBlackList.filter(
                                                                                        (
                                                                                          w
                                                                                        ) =>
                                                                                          item.showText &&
                                                                                          item
                                                                                            .showText
                                                                                            .length >
                                                                                            0 &&
                                                                                          (item.showText
                                                                                            .replaceAll(
                                                                                              "\n",
                                                                                              ""
                                                                                            )
                                                                                            .indexOf(
                                                                                              w.value
                                                                                            ) >
                                                                                            -1 ||
                                                                                            w.value.indexOf(
                                                                                              item.showText.replaceAll(
                                                                                                "\n",
                                                                                                ""
                                                                                              )
                                                                                            ) >
                                                                                              -1)
                                                                                      )
                                                                                        .length
                                                                                        ? _c(
                                                                                            "div",
                                                                                            {
                                                                                              staticClass:
                                                                                                "ocr-show-item-action-bottom",
                                                                                              staticStyle:
                                                                                                {
                                                                                                  "font-size":
                                                                                                    "12px",
                                                                                                },
                                                                                            },
                                                                                            [
                                                                                              _vm.termNotes.filter(
                                                                                                (
                                                                                                  w
                                                                                                ) =>
                                                                                                  w.value &&
                                                                                                  item.showText
                                                                                                    .replaceAll(
                                                                                                      "\n",
                                                                                                      ""
                                                                                                    )
                                                                                                    .indexOf(
                                                                                                      w.value
                                                                                                    ) >
                                                                                                    -1
                                                                                              )
                                                                                                .length
                                                                                                ? _c(
                                                                                                    "div",
                                                                                                    [
                                                                                                      _c(
                                                                                                        "div",
                                                                                                        {
                                                                                                          staticStyle:
                                                                                                            {
                                                                                                              "font-weight":
                                                                                                                "bold",
                                                                                                              "padding-bottom":
                                                                                                                "8px",
                                                                                                            },
                                                                                                        },
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            "表记："
                                                                                                          ),
                                                                                                        ]
                                                                                                      ),
                                                                                                      _vm._l(
                                                                                                        _vm.termNotes.filter(
                                                                                                          (
                                                                                                            w
                                                                                                          ) =>
                                                                                                            w.value &&
                                                                                                            item.showText
                                                                                                              .replaceAll(
                                                                                                                "\n",
                                                                                                                ""
                                                                                                              )
                                                                                                              .indexOf(
                                                                                                                w.value
                                                                                                              ) >
                                                                                                              -1
                                                                                                        ),
                                                                                                        function (
                                                                                                          word,
                                                                                                          wordIdx
                                                                                                        ) {
                                                                                                          return _c(
                                                                                                            "div",
                                                                                                            {
                                                                                                              key: wordIdx,
                                                                                                              staticStyle:
                                                                                                                {
                                                                                                                  "padding-bottom":
                                                                                                                    "8px",
                                                                                                                },
                                                                                                            },
                                                                                                            [
                                                                                                              _c(
                                                                                                                "div",
                                                                                                                {
                                                                                                                  staticStyle:
                                                                                                                    {
                                                                                                                      "justify-content":
                                                                                                                        "space-between",
                                                                                                                      display:
                                                                                                                        "flex",
                                                                                                                    },
                                                                                                                },
                                                                                                                [
                                                                                                                  _c(
                                                                                                                    "span",
                                                                                                                    [
                                                                                                                      _vm._v(
                                                                                                                        _vm._s(
                                                                                                                          word.value +
                                                                                                                            "→" +
                                                                                                                            word.target
                                                                                                                        ) +
                                                                                                                          _vm._s(
                                                                                                                            word.isProduction
                                                                                                                              ? "（作品）"
                                                                                                                              : ""
                                                                                                                          )
                                                                                                                      ),
                                                                                                                    ]
                                                                                                                  ),
                                                                                                                  _c(
                                                                                                                    "span",
                                                                                                                    [
                                                                                                                      _vm.changeAll &&
                                                                                                                      _vm.getIsAddTableNote(
                                                                                                                        item,
                                                                                                                        word
                                                                                                                      )
                                                                                                                        ? _c(
                                                                                                                            "a",
                                                                                                                            {
                                                                                                                              on: {
                                                                                                                                mousedown:
                                                                                                                                  function (
                                                                                                                                    $event
                                                                                                                                  ) {
                                                                                                                                    $event.stopPropagation()
                                                                                                                                    return _vm.addTableNote(
                                                                                                                                      item,
                                                                                                                                      word
                                                                                                                                    )
                                                                                                                                  },
                                                                                                                              },
                                                                                                                            },
                                                                                                                            [
                                                                                                                              _vm._v(
                                                                                                                                "替换"
                                                                                                                              ),
                                                                                                                            ]
                                                                                                                          )
                                                                                                                        : _vm._e(),
                                                                                                                    ]
                                                                                                                  ),
                                                                                                                ]
                                                                                                              ),
                                                                                                            ]
                                                                                                          )
                                                                                                        }
                                                                                                      ),
                                                                                                    ],
                                                                                                    2
                                                                                                  )
                                                                                                : _vm._e(),
                                                                                              _vm.terms.filter(
                                                                                                (
                                                                                                  w
                                                                                                ) =>
                                                                                                  (item.originalText &&
                                                                                                    item
                                                                                                      .originalText
                                                                                                      .length >
                                                                                                      0 &&
                                                                                                    w.valueX &&
                                                                                                    (item.originalText
                                                                                                      .replaceAll(
                                                                                                        "\n",
                                                                                                        ""
                                                                                                      )
                                                                                                      .indexOf(
                                                                                                        w.valueX
                                                                                                      ) >
                                                                                                      -1 ||
                                                                                                      w.valueX.indexOf(
                                                                                                        item.originalText.replaceAll(
                                                                                                          "\n",
                                                                                                          ""
                                                                                                        )
                                                                                                      ) >
                                                                                                        -1)) ||
                                                                                                  (item.originalText &&
                                                                                                    item
                                                                                                      .originalText
                                                                                                      .length >
                                                                                                      0 &&
                                                                                                    w.valueM &&
                                                                                                    (item.originalText
                                                                                                      .replaceAll(
                                                                                                        "\n",
                                                                                                        ""
                                                                                                      )
                                                                                                      .indexOf(
                                                                                                        w.valueM
                                                                                                      ) >
                                                                                                      -1 ||
                                                                                                      w.valueM.indexOf(
                                                                                                        item.originalText.replaceAll(
                                                                                                          "\n",
                                                                                                          ""
                                                                                                        )
                                                                                                      ) >
                                                                                                        -1)) ||
                                                                                                  (item.showText &&
                                                                                                    item
                                                                                                      .showText
                                                                                                      .length >
                                                                                                      0 &&
                                                                                                    w.targetX &&
                                                                                                    (item.showText
                                                                                                      .replaceAll(
                                                                                                        "\n",
                                                                                                        ""
                                                                                                      )
                                                                                                      .indexOf(
                                                                                                        w.targetX
                                                                                                      ) >
                                                                                                      -1 ||
                                                                                                      w.targetX.indexOf(
                                                                                                        item.showText.replaceAll(
                                                                                                          "\n",
                                                                                                          ""
                                                                                                        )
                                                                                                      ) >
                                                                                                        -1)) ||
                                                                                                  (item.showText &&
                                                                                                    item
                                                                                                      .showText
                                                                                                      .length >
                                                                                                      0 &&
                                                                                                    w.targetM &&
                                                                                                    (item.showText
                                                                                                      .replaceAll(
                                                                                                        "\n",
                                                                                                        ""
                                                                                                      )
                                                                                                      .indexOf(
                                                                                                        w.targetM
                                                                                                      ) >
                                                                                                      -1 ||
                                                                                                      w.targetM.indexOf(
                                                                                                        item.showText.replaceAll(
                                                                                                          "\n",
                                                                                                          ""
                                                                                                        )
                                                                                                      ) >
                                                                                                        -1)) ||
                                                                                                  (item.showText &&
                                                                                                    item
                                                                                                      .showText
                                                                                                      .length >
                                                                                                      0 &&
                                                                                                    w.target &&
                                                                                                    (item.showText
                                                                                                      .replaceAll(
                                                                                                        "\n",
                                                                                                        ""
                                                                                                      )
                                                                                                      .indexOf(
                                                                                                        w.target
                                                                                                      ) >
                                                                                                      -1 ||
                                                                                                      w.target.indexOf(
                                                                                                        item.showText.replaceAll(
                                                                                                          "\n",
                                                                                                          ""
                                                                                                        )
                                                                                                      ) >
                                                                                                        -1)) ||
                                                                                                  (item.originalText &&
                                                                                                    item
                                                                                                      .originalText
                                                                                                      .length >
                                                                                                      0 &&
                                                                                                    w.value &&
                                                                                                    (item.originalText
                                                                                                      .replaceAll(
                                                                                                        "\n",
                                                                                                        ""
                                                                                                      )
                                                                                                      .indexOf(
                                                                                                        w.value
                                                                                                      ) >
                                                                                                      -1 ||
                                                                                                      w.value.indexOf(
                                                                                                        item.originalText.replaceAll(
                                                                                                          "\n",
                                                                                                          ""
                                                                                                        )
                                                                                                      ) >
                                                                                                        -1))
                                                                                              )
                                                                                                .length
                                                                                                ? _c(
                                                                                                    "div",
                                                                                                    [
                                                                                                      _c(
                                                                                                        "div",
                                                                                                        {
                                                                                                          staticStyle:
                                                                                                            {
                                                                                                              color:
                                                                                                                "green",
                                                                                                              "font-weight":
                                                                                                                "bold",
                                                                                                              "padding-bottom":
                                                                                                                "8px",
                                                                                                            },
                                                                                                        },
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            "术语："
                                                                                                          ),
                                                                                                        ]
                                                                                                      ),
                                                                                                      _vm._l(
                                                                                                        _vm.terms.filter(
                                                                                                          (
                                                                                                            w
                                                                                                          ) =>
                                                                                                            (item.originalText &&
                                                                                                              item
                                                                                                                .originalText
                                                                                                                .length >
                                                                                                                0 &&
                                                                                                              w.valueX &&
                                                                                                              (item.originalText
                                                                                                                .replaceAll(
                                                                                                                  "\n",
                                                                                                                  ""
                                                                                                                )
                                                                                                                .indexOf(
                                                                                                                  w.valueX
                                                                                                                ) >
                                                                                                                -1 ||
                                                                                                                w.valueX.indexOf(
                                                                                                                  item.originalText.replaceAll(
                                                                                                                    "\n",
                                                                                                                    ""
                                                                                                                  )
                                                                                                                ) >
                                                                                                                  -1)) ||
                                                                                                            (item.originalText &&
                                                                                                              item
                                                                                                                .originalText
                                                                                                                .length >
                                                                                                                0 &&
                                                                                                              w.valueM &&
                                                                                                              (item.originalText
                                                                                                                .replaceAll(
                                                                                                                  "\n",
                                                                                                                  ""
                                                                                                                )
                                                                                                                .indexOf(
                                                                                                                  w.valueM
                                                                                                                ) >
                                                                                                                -1 ||
                                                                                                                w.valueM.indexOf(
                                                                                                                  item.originalText.replaceAll(
                                                                                                                    "\n",
                                                                                                                    ""
                                                                                                                  )
                                                                                                                ) >
                                                                                                                  -1)) ||
                                                                                                            (item.showText &&
                                                                                                              item
                                                                                                                .showText
                                                                                                                .length >
                                                                                                                0 &&
                                                                                                              w.targetX &&
                                                                                                              (item.showText
                                                                                                                .replaceAll(
                                                                                                                  "\n",
                                                                                                                  ""
                                                                                                                )
                                                                                                                .indexOf(
                                                                                                                  w.targetX
                                                                                                                ) >
                                                                                                                -1 ||
                                                                                                                w.targetX.indexOf(
                                                                                                                  item.showText.replaceAll(
                                                                                                                    "\n",
                                                                                                                    ""
                                                                                                                  )
                                                                                                                ) >
                                                                                                                  -1)) ||
                                                                                                            (item.showText &&
                                                                                                              item
                                                                                                                .showText
                                                                                                                .length >
                                                                                                                0 &&
                                                                                                              w.targetM &&
                                                                                                              (item.showText
                                                                                                                .replaceAll(
                                                                                                                  "\n",
                                                                                                                  ""
                                                                                                                )
                                                                                                                .indexOf(
                                                                                                                  w.targetM
                                                                                                                ) >
                                                                                                                -1 ||
                                                                                                                w.targetM.indexOf(
                                                                                                                  item.showText.replaceAll(
                                                                                                                    "\n",
                                                                                                                    ""
                                                                                                                  )
                                                                                                                ) >
                                                                                                                  -1)) ||
                                                                                                            (item.showText &&
                                                                                                              item
                                                                                                                .showText
                                                                                                                .length >
                                                                                                                0 &&
                                                                                                              w.target &&
                                                                                                              (item.showText
                                                                                                                .replaceAll(
                                                                                                                  "\n",
                                                                                                                  ""
                                                                                                                )
                                                                                                                .indexOf(
                                                                                                                  w.target
                                                                                                                ) >
                                                                                                                -1 ||
                                                                                                                w.target.indexOf(
                                                                                                                  item.showText.replaceAll(
                                                                                                                    "\n",
                                                                                                                    ""
                                                                                                                  )
                                                                                                                ) >
                                                                                                                  -1)) ||
                                                                                                            (item.originalText &&
                                                                                                              item
                                                                                                                .originalText
                                                                                                                .length >
                                                                                                                0 &&
                                                                                                              w.value &&
                                                                                                              (item.originalText
                                                                                                                .replaceAll(
                                                                                                                  "\n",
                                                                                                                  ""
                                                                                                                )
                                                                                                                .indexOf(
                                                                                                                  w.value
                                                                                                                ) >
                                                                                                                -1 ||
                                                                                                                w.value.indexOf(
                                                                                                                  item.originalText.replaceAll(
                                                                                                                    "\n",
                                                                                                                    ""
                                                                                                                  )
                                                                                                                ) >
                                                                                                                  -1))
                                                                                                        ),
                                                                                                        function (
                                                                                                          word,
                                                                                                          wordIdx
                                                                                                        ) {
                                                                                                          return _c(
                                                                                                            "div",
                                                                                                            {
                                                                                                              key: wordIdx,
                                                                                                              staticClass:
                                                                                                                "term-item",
                                                                                                              staticStyle:
                                                                                                                {
                                                                                                                  "padding-bottom":
                                                                                                                    "8px",
                                                                                                                },
                                                                                                            },
                                                                                                            [
                                                                                                              _c(
                                                                                                                "span",
                                                                                                                [
                                                                                                                  _vm._v(
                                                                                                                    "\n                                      " +
                                                                                                                      _vm._s(
                                                                                                                        word.value +
                                                                                                                          "→" +
                                                                                                                          word.target +
                                                                                                                          (_vm.termsPhoneticsAll.findIndex(
                                                                                                                            (
                                                                                                                              tp
                                                                                                                            ) =>
                                                                                                                              (tp.processedFamilyName ||
                                                                                                                                "") +
                                                                                                                                (tp.processedGivenName ||
                                                                                                                                  "") ==
                                                                                                                                word.target ||
                                                                                                                              tp.processedName ==
                                                                                                                                word.target
                                                                                                                          ) >
                                                                                                                            -1 &&
                                                                                                                          _vm.termsPhoneticsAll.find(
                                                                                                                            (
                                                                                                                              tp
                                                                                                                            ) =>
                                                                                                                              (tp.processedFamilyName ||
                                                                                                                                "") +
                                                                                                                                (tp.processedGivenName ||
                                                                                                                                  "") ==
                                                                                                                                word.target ||
                                                                                                                              tp.processedName ==
                                                                                                                                word.target
                                                                                                                          )
                                                                                                                            .phoneticText
                                                                                                                            ? "(" +
                                                                                                                              _vm.termsPhoneticsAll.find(
                                                                                                                                (
                                                                                                                                  tp
                                                                                                                                ) =>
                                                                                                                                  (tp.processedFamilyName ||
                                                                                                                                    "") +
                                                                                                                                    (tp.processedGivenName ||
                                                                                                                                      "") ==
                                                                                                                                    word.target ||
                                                                                                                                  tp.processedName ==
                                                                                                                                    word.target
                                                                                                                              )
                                                                                                                                .phoneticText +
                                                                                                                              ")"
                                                                                                                            : "")
                                                                                                                      ) +
                                                                                                                      "\n                                      "
                                                                                                                  ),
                                                                                                                  _c(
                                                                                                                    "span",
                                                                                                                    {
                                                                                                                      staticStyle:
                                                                                                                        {
                                                                                                                          "font-weight":
                                                                                                                            "700",
                                                                                                                          "margin-left":
                                                                                                                            "10px",
                                                                                                                        },
                                                                                                                    },
                                                                                                                    [
                                                                                                                      _vm._v(
                                                                                                                        _vm._s(
                                                                                                                          word.status ==
                                                                                                                            "已确认"
                                                                                                                            ? ``
                                                                                                                            : "未确认"
                                                                                                                        )
                                                                                                                      ),
                                                                                                                    ]
                                                                                                                  ),
                                                                                                                ]
                                                                                                              ),
                                                                                                              _c(
                                                                                                                "span",
                                                                                                                {
                                                                                                                  staticStyle:
                                                                                                                    {
                                                                                                                      display:
                                                                                                                        "flex",
                                                                                                                      "min-width":
                                                                                                                        "55px",
                                                                                                                      "justify-content":
                                                                                                                        "end",
                                                                                                                    },
                                                                                                                },
                                                                                                                [
                                                                                                                  _vm.termsPhoneticsAll.findIndex(
                                                                                                                    (
                                                                                                                      tp
                                                                                                                    ) =>
                                                                                                                      (tp.processedFamilyName ||
                                                                                                                        "") +
                                                                                                                        (tp.processedGivenName ||
                                                                                                                          "") ==
                                                                                                                        word.target ||
                                                                                                                      tp.processedName ==
                                                                                                                        word.target
                                                                                                                  ) >
                                                                                                                    -1 &&
                                                                                                                  _vm.termsPhoneticsAll.find(
                                                                                                                    (
                                                                                                                      tp
                                                                                                                    ) =>
                                                                                                                      (tp.processedFamilyName ||
                                                                                                                        "") +
                                                                                                                        (tp.processedGivenName ||
                                                                                                                          "") ==
                                                                                                                        word.target ||
                                                                                                                      tp.processedName ==
                                                                                                                        word.target
                                                                                                                  )
                                                                                                                    .phoneticText
                                                                                                                    ? _c(
                                                                                                                        "a",
                                                                                                                        {
                                                                                                                          staticStyle:
                                                                                                                            {
                                                                                                                              "margin-right":
                                                                                                                                "5px",
                                                                                                                            },
                                                                                                                          on: {
                                                                                                                            click:
                                                                                                                              function (
                                                                                                                                $event
                                                                                                                              ) {
                                                                                                                                $event.stopPropagation()
                                                                                                                                _vm.addPhoneticAll(
                                                                                                                                  item,
                                                                                                                                  _vm.termsPhoneticsAll.find(
                                                                                                                                    (
                                                                                                                                      tp
                                                                                                                                    ) =>
                                                                                                                                      (tp.processedFamilyName ||
                                                                                                                                        "") +
                                                                                                                                        (tp.processedGivenName ||
                                                                                                                                          "") ==
                                                                                                                                        word.target ||
                                                                                                                                      tp.processedName ==
                                                                                                                                        word.target
                                                                                                                                  )
                                                                                                                                )
                                                                                                                              },
                                                                                                                          },
                                                                                                                        },
                                                                                                                        [
                                                                                                                          _vm._v(
                                                                                                                            "\n                                        " +
                                                                                                                              _vm._s(
                                                                                                                                _vm.getIsAddPhoneticTextAll(
                                                                                                                                  item,
                                                                                                                                  _vm.termsPhoneticsAll.find(
                                                                                                                                    (
                                                                                                                                      tp
                                                                                                                                    ) =>
                                                                                                                                      (tp.processedFamilyName ||
                                                                                                                                        "") +
                                                                                                                                        (tp.processedGivenName ||
                                                                                                                                          "") ==
                                                                                                                                        word.target ||
                                                                                                                                      tp.processedName ==
                                                                                                                                        word.target
                                                                                                                                  )
                                                                                                                                )
                                                                                                                                  ? "撤销"
                                                                                                                                  : "注音"
                                                                                                                              ) +
                                                                                                                              "\n                                      "
                                                                                                                          ),
                                                                                                                        ]
                                                                                                                      )
                                                                                                                    : _vm._e(),
                                                                                                                  _c(
                                                                                                                    "a",
                                                                                                                    {
                                                                                                                      on: {
                                                                                                                        mousedown:
                                                                                                                          function (
                                                                                                                            $event
                                                                                                                          ) {
                                                                                                                            $event.stopPropagation()
                                                                                                                            return _vm.openTerm(
                                                                                                                              word
                                                                                                                            )
                                                                                                                          },
                                                                                                                      },
                                                                                                                    },
                                                                                                                    [
                                                                                                                      _vm._v(
                                                                                                                        "查看"
                                                                                                                      ),
                                                                                                                    ]
                                                                                                                  ),
                                                                                                                ]
                                                                                                              ),
                                                                                                            ]
                                                                                                          )
                                                                                                        }
                                                                                                      ),
                                                                                                    ],
                                                                                                    2
                                                                                                  )
                                                                                                : _vm._e(),
                                                                                              _vm
                                                                                                .checkPhoneticList
                                                                                                .length
                                                                                                ? _c(
                                                                                                    "div",
                                                                                                    [
                                                                                                      _c(
                                                                                                        "div",
                                                                                                        {
                                                                                                          staticStyle:
                                                                                                            {
                                                                                                              display:
                                                                                                                "flex",
                                                                                                              "justify-content":
                                                                                                                "space-between",
                                                                                                              "padding-bottom":
                                                                                                                "8px",
                                                                                                            },
                                                                                                        },
                                                                                                        [
                                                                                                          _c(
                                                                                                            "span",
                                                                                                            {
                                                                                                              staticStyle:
                                                                                                                {
                                                                                                                  color:
                                                                                                                    "green",
                                                                                                                  "font-weight":
                                                                                                                    "bold",
                                                                                                                },
                                                                                                            },
                                                                                                            [
                                                                                                              _vm._v(
                                                                                                                "需注音："
                                                                                                              ),
                                                                                                            ]
                                                                                                          ),
                                                                                                          _c(
                                                                                                            "a",
                                                                                                            {
                                                                                                              on: {
                                                                                                                click:
                                                                                                                  function (
                                                                                                                    $event
                                                                                                                  ) {
                                                                                                                    $event.stopPropagation()
                                                                                                                    return _vm.addAllYahooPhotic(
                                                                                                                      item
                                                                                                                    )
                                                                                                                  },
                                                                                                              },
                                                                                                            },
                                                                                                            [
                                                                                                              _vm._v(
                                                                                                                _vm._s(
                                                                                                                  _vm.checkHasAllPhonetic(
                                                                                                                    item
                                                                                                                  )
                                                                                                                    ? "全部注音"
                                                                                                                    : "全部撤销"
                                                                                                                )
                                                                                                              ),
                                                                                                            ]
                                                                                                          ),
                                                                                                        ]
                                                                                                      ),
                                                                                                      _vm._l(
                                                                                                        _vm.checkPhoneticList,
                                                                                                        function (
                                                                                                          word,
                                                                                                          wordIdx
                                                                                                        ) {
                                                                                                          return _c(
                                                                                                            "div",
                                                                                                            {
                                                                                                              key: wordIdx,
                                                                                                              staticStyle:
                                                                                                                {
                                                                                                                  "padding-bottom":
                                                                                                                    "8px",
                                                                                                                  display:
                                                                                                                    "flex",
                                                                                                                  "justify-content":
                                                                                                                    "space-between",
                                                                                                                },
                                                                                                            },
                                                                                                            [
                                                                                                              _c(
                                                                                                                "span",
                                                                                                                [
                                                                                                                  _vm._v(
                                                                                                                    " " +
                                                                                                                      _vm._s(
                                                                                                                        word.surface
                                                                                                                      ) +
                                                                                                                      "（" +
                                                                                                                      _vm._s(
                                                                                                                        word.furigana
                                                                                                                      ) +
                                                                                                                      "） "
                                                                                                                  ),
                                                                                                                ]
                                                                                                              ),
                                                                                                              _c(
                                                                                                                "span",
                                                                                                                {
                                                                                                                  staticStyle:
                                                                                                                    {
                                                                                                                      display:
                                                                                                                        "inline-block",
                                                                                                                      "min-width":
                                                                                                                        "25px",
                                                                                                                    },
                                                                                                                },
                                                                                                                [
                                                                                                                  _c(
                                                                                                                    "a",
                                                                                                                    {
                                                                                                                      on: {
                                                                                                                        click:
                                                                                                                          function (
                                                                                                                            $event
                                                                                                                          ) {
                                                                                                                            $event.stopPropagation()
                                                                                                                            return _vm.addYahooPhotic(
                                                                                                                              item,
                                                                                                                              word
                                                                                                                            )
                                                                                                                          },
                                                                                                                      },
                                                                                                                    },
                                                                                                                    [
                                                                                                                      _vm._v(
                                                                                                                        _vm._s(
                                                                                                                          _vm.checkHasPhonetic(
                                                                                                                            item,
                                                                                                                            word
                                                                                                                          )
                                                                                                                            ? "注音"
                                                                                                                            : "撤销"
                                                                                                                        )
                                                                                                                      ),
                                                                                                                    ]
                                                                                                                  ),
                                                                                                                ]
                                                                                                              ),
                                                                                                            ]
                                                                                                          )
                                                                                                        }
                                                                                                      ),
                                                                                                    ],
                                                                                                    2
                                                                                                  )
                                                                                                : _vm._e(),
                                                                                              _vm.phoneticBlackList.filter(
                                                                                                (
                                                                                                  w
                                                                                                ) =>
                                                                                                  item.showText &&
                                                                                                  item
                                                                                                    .showText
                                                                                                    .length >
                                                                                                    0 &&
                                                                                                  (item.showText
                                                                                                    .replaceAll(
                                                                                                      "\n",
                                                                                                      ""
                                                                                                    )
                                                                                                    .indexOf(
                                                                                                      w.value
                                                                                                    ) >
                                                                                                    -1 ||
                                                                                                    w.value.indexOf(
                                                                                                      item.showText.replaceAll(
                                                                                                        "\n",
                                                                                                        ""
                                                                                                      )
                                                                                                    ) >
                                                                                                      -1)
                                                                                              )
                                                                                                .length
                                                                                                ? _c(
                                                                                                    "div",
                                                                                                    [
                                                                                                      _c(
                                                                                                        "div",
                                                                                                        {
                                                                                                          staticStyle:
                                                                                                            {
                                                                                                              color:
                                                                                                                "red",
                                                                                                              "font-weight":
                                                                                                                "bold",
                                                                                                              "padding-bottom":
                                                                                                                "8px",
                                                                                                            },
                                                                                                        },
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            "不注音："
                                                                                                          ),
                                                                                                        ]
                                                                                                      ),
                                                                                                      _vm._l(
                                                                                                        _vm.phoneticBlackList.filter(
                                                                                                          (
                                                                                                            w
                                                                                                          ) =>
                                                                                                            item.showText &&
                                                                                                            item
                                                                                                              .showText
                                                                                                              .length >
                                                                                                              0 &&
                                                                                                            (item.showText
                                                                                                              .replaceAll(
                                                                                                                "\n",
                                                                                                                ""
                                                                                                              )
                                                                                                              .indexOf(
                                                                                                                w.value
                                                                                                              ) >
                                                                                                              -1 ||
                                                                                                              w.value.indexOf(
                                                                                                                item.showText.replaceAll(
                                                                                                                  "\n",
                                                                                                                  ""
                                                                                                                )
                                                                                                              ) >
                                                                                                                -1)
                                                                                                        ),
                                                                                                        function (
                                                                                                          word,
                                                                                                          wordIdx
                                                                                                        ) {
                                                                                                          return _c(
                                                                                                            "div",
                                                                                                            {
                                                                                                              key: wordIdx,
                                                                                                              staticStyle:
                                                                                                                {
                                                                                                                  "padding-bottom":
                                                                                                                    "8px",
                                                                                                                },
                                                                                                            },
                                                                                                            [
                                                                                                              _vm._v(
                                                                                                                "\n                                    " +
                                                                                                                  _vm._s(
                                                                                                                    word.value
                                                                                                                  ) +
                                                                                                                  "\n                                  "
                                                                                                              ),
                                                                                                            ]
                                                                                                          )
                                                                                                        }
                                                                                                      ),
                                                                                                    ],
                                                                                                    2
                                                                                                  )
                                                                                                : _vm._e(),
                                                                                              _vm.glossaryTags.filter(
                                                                                                (
                                                                                                  w
                                                                                                ) =>
                                                                                                  (item.originalText &&
                                                                                                    (item.originalText
                                                                                                      .replaceAll(
                                                                                                        "\n",
                                                                                                        ""
                                                                                                      )
                                                                                                      .indexOf(
                                                                                                        w.value
                                                                                                      ) >
                                                                                                      -1 ||
                                                                                                      w.value.indexOf(
                                                                                                        item.originalText.replaceAll(
                                                                                                          "\n",
                                                                                                          ""
                                                                                                        )
                                                                                                      ) >
                                                                                                        -1)) ||
                                                                                                  (item.showText &&
                                                                                                    (item.showText
                                                                                                      .replaceAll(
                                                                                                        "\n",
                                                                                                        ""
                                                                                                      )
                                                                                                      .indexOf(
                                                                                                        w.target
                                                                                                      ) >
                                                                                                      -1 ||
                                                                                                      w.target.indexOf(
                                                                                                        item.showText.replaceAll(
                                                                                                          "\n",
                                                                                                          ""
                                                                                                        )
                                                                                                      ) >
                                                                                                        -1))
                                                                                              )
                                                                                                .length
                                                                                                ? _c(
                                                                                                    "div",
                                                                                                    [
                                                                                                      _c(
                                                                                                        "div",
                                                                                                        {
                                                                                                          staticStyle:
                                                                                                            {
                                                                                                              color:
                                                                                                                "green",
                                                                                                              "font-weight":
                                                                                                                "bold",
                                                                                                              "padding-bottom":
                                                                                                                "8px",
                                                                                                            },
                                                                                                        },
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            "专有词汇："
                                                                                                          ),
                                                                                                        ]
                                                                                                      ),
                                                                                                      _vm._l(
                                                                                                        _vm.glossaryTags.filter(
                                                                                                          (
                                                                                                            w
                                                                                                          ) =>
                                                                                                            (item.originalText &&
                                                                                                              (item.originalText
                                                                                                                .replaceAll(
                                                                                                                  "\n",
                                                                                                                  ""
                                                                                                                )
                                                                                                                .indexOf(
                                                                                                                  w.value
                                                                                                                ) >
                                                                                                                -1 ||
                                                                                                                w.value.indexOf(
                                                                                                                  item.originalText.replaceAll(
                                                                                                                    "\n",
                                                                                                                    ""
                                                                                                                  )
                                                                                                                ) >
                                                                                                                  -1)) ||
                                                                                                            (item.showText &&
                                                                                                              (item.showText
                                                                                                                .replaceAll(
                                                                                                                  "\n",
                                                                                                                  ""
                                                                                                                )
                                                                                                                .indexOf(
                                                                                                                  w.target
                                                                                                                ) >
                                                                                                                -1 ||
                                                                                                                w.target.indexOf(
                                                                                                                  item.showText.replaceAll(
                                                                                                                    "\n",
                                                                                                                    ""
                                                                                                                  )
                                                                                                                ) >
                                                                                                                  -1))
                                                                                                        ),
                                                                                                        function (
                                                                                                          word,
                                                                                                          wordIdx
                                                                                                        ) {
                                                                                                          return _c(
                                                                                                            "div",
                                                                                                            {
                                                                                                              key: wordIdx,
                                                                                                              staticStyle:
                                                                                                                {
                                                                                                                  "padding-bottom":
                                                                                                                    "8px",
                                                                                                                },
                                                                                                            },
                                                                                                            [
                                                                                                              _vm._v(
                                                                                                                "\n                                    " +
                                                                                                                  _vm._s(
                                                                                                                    word.value +
                                                                                                                      "→" +
                                                                                                                      word.target
                                                                                                                  ) +
                                                                                                                  "\n                                  "
                                                                                                              ),
                                                                                                            ]
                                                                                                          )
                                                                                                        }
                                                                                                      ),
                                                                                                    ],
                                                                                                    2
                                                                                                  )
                                                                                                : _vm._e(),
                                                                                              _vm.termBacks.filter(
                                                                                                (
                                                                                                  w
                                                                                                ) =>
                                                                                                  (item.originalText &&
                                                                                                    w.value &&
                                                                                                    item
                                                                                                      .originalText
                                                                                                      .length >
                                                                                                      0 &&
                                                                                                    (item.originalText
                                                                                                      .replaceAll(
                                                                                                        "\n",
                                                                                                        ""
                                                                                                      )
                                                                                                      .indexOf(
                                                                                                        w.value
                                                                                                      ) >
                                                                                                      -1 ||
                                                                                                      w.value.indexOf(
                                                                                                        item.originalText.replaceAll(
                                                                                                          "\n",
                                                                                                          ""
                                                                                                        )
                                                                                                      ) >
                                                                                                        -1)) ||
                                                                                                  (item.showText &&
                                                                                                    w.target &&
                                                                                                    item
                                                                                                      .showText
                                                                                                      .length >
                                                                                                      0 &&
                                                                                                    (item.showText
                                                                                                      .replaceAll(
                                                                                                        "\n",
                                                                                                        ""
                                                                                                      )
                                                                                                      .indexOf(
                                                                                                        w.target
                                                                                                      ) >
                                                                                                      -1 ||
                                                                                                      w.target.indexOf(
                                                                                                        item.showText.replaceAll(
                                                                                                          "\n",
                                                                                                          ""
                                                                                                        )
                                                                                                      ) >
                                                                                                        -1))
                                                                                              )
                                                                                                .length
                                                                                                ? _c(
                                                                                                    "div",
                                                                                                    [
                                                                                                      _c(
                                                                                                        "div",
                                                                                                        {
                                                                                                          staticStyle:
                                                                                                            {
                                                                                                              color:
                                                                                                                "green",
                                                                                                              "font-weight":
                                                                                                                "bold",
                                                                                                              "padding-bottom":
                                                                                                                "8px",
                                                                                                            },
                                                                                                        },
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            "背景册："
                                                                                                          ),
                                                                                                        ]
                                                                                                      ),
                                                                                                      _vm._l(
                                                                                                        _vm.termBacks.filter(
                                                                                                          (
                                                                                                            w
                                                                                                          ) =>
                                                                                                            (item.originalText &&
                                                                                                              w.value &&
                                                                                                              item
                                                                                                                .originalText
                                                                                                                .length >
                                                                                                                0 &&
                                                                                                              (item.originalText
                                                                                                                .replaceAll(
                                                                                                                  "\n",
                                                                                                                  ""
                                                                                                                )
                                                                                                                .indexOf(
                                                                                                                  w.value
                                                                                                                ) >
                                                                                                                -1 ||
                                                                                                                w.value.indexOf(
                                                                                                                  item.originalText.replaceAll(
                                                                                                                    "\n",
                                                                                                                    ""
                                                                                                                  )
                                                                                                                ) >
                                                                                                                  -1)) ||
                                                                                                            (item.showText &&
                                                                                                              w.target &&
                                                                                                              item
                                                                                                                .showText
                                                                                                                .length >
                                                                                                                0 &&
                                                                                                              (item.showText
                                                                                                                .replaceAll(
                                                                                                                  "\n",
                                                                                                                  ""
                                                                                                                )
                                                                                                                .indexOf(
                                                                                                                  w.target
                                                                                                                ) >
                                                                                                                -1 ||
                                                                                                                w.target.indexOf(
                                                                                                                  item.showText.replaceAll(
                                                                                                                    "\n",
                                                                                                                    ""
                                                                                                                  )
                                                                                                                ) >
                                                                                                                  -1))
                                                                                                        ),
                                                                                                        function (
                                                                                                          word,
                                                                                                          wordIdx
                                                                                                        ) {
                                                                                                          return _c(
                                                                                                            "div",
                                                                                                            {
                                                                                                              key: wordIdx,
                                                                                                              staticStyle:
                                                                                                                {
                                                                                                                  "padding-bottom":
                                                                                                                    "8px",
                                                                                                                  display:
                                                                                                                    "flex",
                                                                                                                  "justify-content":
                                                                                                                    "space-between",
                                                                                                                },
                                                                                                            },
                                                                                                            [
                                                                                                              _c(
                                                                                                                "span",
                                                                                                                [
                                                                                                                  _vm._v(
                                                                                                                    _vm._s(
                                                                                                                      word.value +
                                                                                                                        "→" +
                                                                                                                        word.target
                                                                                                                    )
                                                                                                                  ),
                                                                                                                ]
                                                                                                              ),
                                                                                                              _c(
                                                                                                                "span",
                                                                                                                {
                                                                                                                  staticStyle:
                                                                                                                    {
                                                                                                                      display:
                                                                                                                        "inline-block",
                                                                                                                      "min-width":
                                                                                                                        "25px",
                                                                                                                    },
                                                                                                                  on: {
                                                                                                                    mousedown:
                                                                                                                      function (
                                                                                                                        $event
                                                                                                                      ) {
                                                                                                                        $event.stopPropagation()
                                                                                                                        return _vm.openTermBack(
                                                                                                                          word
                                                                                                                        )
                                                                                                                      },
                                                                                                                  },
                                                                                                                },
                                                                                                                [
                                                                                                                  _c(
                                                                                                                    "a",
                                                                                                                    [
                                                                                                                      _vm._v(
                                                                                                                        "查看"
                                                                                                                      ),
                                                                                                                    ]
                                                                                                                  ),
                                                                                                                ]
                                                                                                              ),
                                                                                                            ]
                                                                                                          )
                                                                                                        }
                                                                                                      ),
                                                                                                    ],
                                                                                                    2
                                                                                                  )
                                                                                                : _vm._e(),
                                                                                              _vm
                                                                                                .blacklist
                                                                                                .length
                                                                                                ? _c(
                                                                                                    "div",
                                                                                                    [
                                                                                                      _vm._l(
                                                                                                        _vm.blacklist,
                                                                                                        function (
                                                                                                          words,
                                                                                                          wordsIdx
                                                                                                        ) {
                                                                                                          return [
                                                                                                            words.classId !=
                                                                                                              12 &&
                                                                                                            _vm.getBlackWords(
                                                                                                              item,
                                                                                                              words
                                                                                                            )
                                                                                                              ? _c(
                                                                                                                  "div",
                                                                                                                  {
                                                                                                                    key: wordsIdx,
                                                                                                                  },
                                                                                                                  [
                                                                                                                    _c(
                                                                                                                      "div",
                                                                                                                      {
                                                                                                                        staticStyle:
                                                                                                                          {
                                                                                                                            color:
                                                                                                                              "red",
                                                                                                                            "font-weight":
                                                                                                                              "bold",
                                                                                                                          },
                                                                                                                      },
                                                                                                                      [
                                                                                                                        _vm._v(
                                                                                                                          _vm._s(
                                                                                                                            words.className
                                                                                                                          ) +
                                                                                                                            "："
                                                                                                                        ),
                                                                                                                      ]
                                                                                                                    ),
                                                                                                                    _c(
                                                                                                                      "div",
                                                                                                                      [
                                                                                                                        _vm._v(
                                                                                                                          _vm._s(
                                                                                                                            _vm.getBlackWords(
                                                                                                                              item,
                                                                                                                              words
                                                                                                                            )
                                                                                                                          )
                                                                                                                        ),
                                                                                                                      ]
                                                                                                                    ),
                                                                                                                  ]
                                                                                                                )
                                                                                                              : _vm.getBlackWordsTwo(
                                                                                                                  item,
                                                                                                                  words
                                                                                                                )
                                                                                                                  .length
                                                                                                              ? _c(
                                                                                                                  "div",
                                                                                                                  {
                                                                                                                    key: wordsIdx,
                                                                                                                  },
                                                                                                                  [
                                                                                                                    _c(
                                                                                                                      "div",
                                                                                                                      {
                                                                                                                        staticStyle:
                                                                                                                          {
                                                                                                                            color:
                                                                                                                              "red",
                                                                                                                            "font-weight":
                                                                                                                              "bold",
                                                                                                                          },
                                                                                                                      },
                                                                                                                      [
                                                                                                                        _vm._v(
                                                                                                                          _vm._s(
                                                                                                                            words.className
                                                                                                                          ) +
                                                                                                                            "："
                                                                                                                        ),
                                                                                                                      ]
                                                                                                                    ),
                                                                                                                    _vm._l(
                                                                                                                      _vm.getBlackWordsTwo(
                                                                                                                        item,
                                                                                                                        words
                                                                                                                      ),
                                                                                                                      function (
                                                                                                                        b,
                                                                                                                        bIdx
                                                                                                                      ) {
                                                                                                                        return _c(
                                                                                                                          "div",
                                                                                                                          {
                                                                                                                            key: bIdx,
                                                                                                                          },
                                                                                                                          [
                                                                                                                            _c(
                                                                                                                              "div",
                                                                                                                              [
                                                                                                                                _vm._v(
                                                                                                                                  _vm._s(
                                                                                                                                    b.title
                                                                                                                                  )
                                                                                                                                ),
                                                                                                                              ]
                                                                                                                            ),
                                                                                                                            _c(
                                                                                                                              "div",
                                                                                                                              [
                                                                                                                                _vm._v(
                                                                                                                                  _vm._s(
                                                                                                                                    b.remark
                                                                                                                                  )
                                                                                                                                ),
                                                                                                                              ]
                                                                                                                            ),
                                                                                                                          ]
                                                                                                                        )
                                                                                                                      }
                                                                                                                    ),
                                                                                                                  ],
                                                                                                                  2
                                                                                                                )
                                                                                                              : _vm._e(),
                                                                                                          ]
                                                                                                        }
                                                                                                      ),
                                                                                                    ],
                                                                                                    2
                                                                                                  )
                                                                                                : _vm._e(),
                                                                                              _vm
                                                                                                .yahooOrKdxList
                                                                                                .length &&
                                                                                              (_vm.yahooOrKdxList.some(
                                                                                                (
                                                                                                  words
                                                                                                ) =>
                                                                                                  _vm.getBlackWordsTwo(
                                                                                                    item,
                                                                                                    words
                                                                                                  )
                                                                                                    .length
                                                                                              ) ||
                                                                                                _vm.yahooOrKdxList.some(
                                                                                                  (
                                                                                                    words
                                                                                                  ) =>
                                                                                                    words.classId !=
                                                                                                      12 &&
                                                                                                    _vm.getBlackWords(
                                                                                                      item,
                                                                                                      words
                                                                                                    )
                                                                                                ))
                                                                                                ? _c(
                                                                                                    "div",
                                                                                                    {
                                                                                                      staticStyle:
                                                                                                        {
                                                                                                          "padding-bottom":
                                                                                                            "8px",
                                                                                                        },
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        "div",
                                                                                                        {
                                                                                                          staticStyle:
                                                                                                            {
                                                                                                              color:
                                                                                                                "red",
                                                                                                              "font-weight":
                                                                                                                "bold",
                                                                                                              "padding-bottom":
                                                                                                                "8px",
                                                                                                              "padding-top":
                                                                                                                "8px",
                                                                                                            },
                                                                                                        },
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            "\n                                    第三方提示参考：\n                                  "
                                                                                                          ),
                                                                                                        ]
                                                                                                      ),
                                                                                                      _vm._l(
                                                                                                        _vm.yahooOrKdxList,
                                                                                                        function (
                                                                                                          words,
                                                                                                          wordsIdx
                                                                                                        ) {
                                                                                                          return [
                                                                                                            words.classId !=
                                                                                                              12 &&
                                                                                                            _vm.getBlackWords(
                                                                                                              item,
                                                                                                              words
                                                                                                            )
                                                                                                              ? _c(
                                                                                                                  "div",
                                                                                                                  {
                                                                                                                    key: wordsIdx,
                                                                                                                  },
                                                                                                                  [
                                                                                                                    _c(
                                                                                                                      "div",
                                                                                                                      [
                                                                                                                        _vm._v(
                                                                                                                          _vm._s(
                                                                                                                            words.className
                                                                                                                          ) +
                                                                                                                            "：" +
                                                                                                                            _vm._s(
                                                                                                                              _vm.getBlackWords(
                                                                                                                                item,
                                                                                                                                words
                                                                                                                              )
                                                                                                                            )
                                                                                                                        ),
                                                                                                                      ]
                                                                                                                    ),
                                                                                                                  ]
                                                                                                                )
                                                                                                              : _vm.getBlackWordsTwo(
                                                                                                                  item,
                                                                                                                  words
                                                                                                                )
                                                                                                                  .length
                                                                                                              ? _c(
                                                                                                                  "div",
                                                                                                                  {
                                                                                                                    key: wordsIdx,
                                                                                                                    staticStyle:
                                                                                                                      {
                                                                                                                        display:
                                                                                                                          "flex",
                                                                                                                        "padding-bottom":
                                                                                                                          "8px",
                                                                                                                      },
                                                                                                                  },
                                                                                                                  [
                                                                                                                    _c(
                                                                                                                      "div",
                                                                                                                      [
                                                                                                                        _vm._v(
                                                                                                                          _vm._s(
                                                                                                                            words.className
                                                                                                                          ) +
                                                                                                                            "："
                                                                                                                        ),
                                                                                                                      ]
                                                                                                                    ),
                                                                                                                    _vm._l(
                                                                                                                      _vm.getBlackWordsTwo(
                                                                                                                        item,
                                                                                                                        words
                                                                                                                      ),
                                                                                                                      function (
                                                                                                                        b,
                                                                                                                        bIdx
                                                                                                                      ) {
                                                                                                                        return _c(
                                                                                                                          "div",
                                                                                                                          {
                                                                                                                            key: bIdx,
                                                                                                                          },
                                                                                                                          [
                                                                                                                            _c(
                                                                                                                              "div",
                                                                                                                              [
                                                                                                                                _vm._v(
                                                                                                                                  _vm._s(
                                                                                                                                    b.title
                                                                                                                                  )
                                                                                                                                ),
                                                                                                                              ]
                                                                                                                            ),
                                                                                                                            _c(
                                                                                                                              "div",
                                                                                                                              [
                                                                                                                                _vm._v(
                                                                                                                                  _vm._s(
                                                                                                                                    b.remark
                                                                                                                                  )
                                                                                                                                ),
                                                                                                                              ]
                                                                                                                            ),
                                                                                                                          ]
                                                                                                                        )
                                                                                                                      }
                                                                                                                    ),
                                                                                                                  ],
                                                                                                                  2
                                                                                                                )
                                                                                                              : _vm._e(),
                                                                                                          ]
                                                                                                        }
                                                                                                      ),
                                                                                                    ],
                                                                                                    2
                                                                                                  )
                                                                                                : _vm._e(),
                                                                                            ]
                                                                                          )
                                                                                        : _vm._e(),
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                              _vm
                                                                                .textTypes
                                                                                .length
                                                                                ? _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "ocr-show-item-action",
                                                                                      style:
                                                                                        _vm.getActionTwoStyle(
                                                                                          _vm
                                                                                            .showImgLeftListPostions[
                                                                                            idx
                                                                                          ],
                                                                                          page,
                                                                                          item,
                                                                                          "left",
                                                                                          idx
                                                                                        ),
                                                                                      on: {
                                                                                        mouseenter:
                                                                                          (
                                                                                            e
                                                                                          ) =>
                                                                                            _vm.leftOut(
                                                                                              e,
                                                                                              item
                                                                                            ),
                                                                                        mousemove:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            $event.stopPropagation()
                                                                                            _vm.isCheckText = true
                                                                                          },
                                                                                      },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "div",
                                                                                        {
                                                                                          staticStyle:
                                                                                            {
                                                                                              "background-color":
                                                                                                "#fff",
                                                                                              padding:
                                                                                                "8px 10px",
                                                                                              "border-radius":
                                                                                                "5px",
                                                                                              "box-shadow":
                                                                                                "1px 1px 2px 1px rgba(0, 0, 0, 0.2)",
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "a-radio-group",
                                                                                            {
                                                                                              attrs:
                                                                                                {
                                                                                                  disabled:
                                                                                                    !_vm
                                                                                                      .selectTextAreaData
                                                                                                      .length ||
                                                                                                    !_vm.checkPermission(
                                                                                                      "textType:edit"
                                                                                                    ) ||
                                                                                                    (_vm
                                                                                                      .taskDetail
                                                                                                      .taskStatus !=
                                                                                                      "可开始" &&
                                                                                                      _vm
                                                                                                        .taskDetail
                                                                                                        .taskStatus !=
                                                                                                        "驳回") ||
                                                                                                    !_vm.changeAll,
                                                                                                  name: "radioGroup",
                                                                                                },
                                                                                              on: {
                                                                                                change:
                                                                                                  (
                                                                                                    e
                                                                                                  ) =>
                                                                                                    _vm.textTypeChange(
                                                                                                      e,
                                                                                                      item
                                                                                                    ),
                                                                                              },
                                                                                              model:
                                                                                                {
                                                                                                  value:
                                                                                                    item.textAreaType,
                                                                                                  callback:
                                                                                                    function (
                                                                                                      $$v
                                                                                                    ) {
                                                                                                      _vm.$set(
                                                                                                        item,
                                                                                                        "textAreaType",
                                                                                                        $$v
                                                                                                      )
                                                                                                    },
                                                                                                  expression:
                                                                                                    "item.textAreaType",
                                                                                                },
                                                                                            },
                                                                                            _vm._l(
                                                                                              _vm.textTypes,
                                                                                              function (
                                                                                                text
                                                                                              ) {
                                                                                                return _c(
                                                                                                  "a-radio",
                                                                                                  {
                                                                                                    key: text.sequenceNo,
                                                                                                    staticStyle:
                                                                                                      {
                                                                                                        "margin-top":
                                                                                                          "3px",
                                                                                                      },
                                                                                                    attrs:
                                                                                                      {
                                                                                                        value:
                                                                                                          text.sequenceNo -
                                                                                                          0,
                                                                                                      },
                                                                                                  },
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      "\n                                    " +
                                                                                                        _vm._s(
                                                                                                          text.usage
                                                                                                        ) +
                                                                                                        _vm._s(
                                                                                                          text.isBold
                                                                                                            ? "（加粗）"
                                                                                                            : ""
                                                                                                        ) +
                                                                                                        "\n                                  "
                                                                                                    ),
                                                                                                  ]
                                                                                                )
                                                                                              }
                                                                                            ),
                                                                                            1
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "ocr-show-item-title",
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                $event.stopPropagation()
                                                                              },
                                                                            mousemove:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                $event.stopPropagation()
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          item
                                                                            .textAreaRegion
                                                                            .selected
                                                                            ? _c(
                                                                                "div",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      item.sequenceNo
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                          item.isProofreadInjury
                                                                            ? _c(
                                                                                "div",
                                                                                {
                                                                                  staticStyle:
                                                                                    {
                                                                                      "background-color":
                                                                                        "red",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "校"
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                          item.isFinalCheckInjury
                                                                            ? _c(
                                                                                "div",
                                                                                {
                                                                                  staticStyle:
                                                                                    {
                                                                                      "background-color":
                                                                                        "red",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "审"
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                          item
                                                                            .textAreaRegion
                                                                            .selected
                                                                            ? _c(
                                                                                "div",
                                                                                [
                                                                                  _c(
                                                                                    "a-icon",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          type: "close",
                                                                                        },
                                                                                      on: {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            $event.stopPropagation()
                                                                                            return _vm.deleteTextArea(
                                                                                              page,
                                                                                              item
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              )
                                                                            : _vm._e(),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      }
                                                    ),
                                                    _vm.leftAnnotationList
                                                      .length &&
                                                    _vm.leftImgShow &&
                                                    _vm.leftAnnotationList[
                                                      _vm.leftAnnotationList.findIndex(
                                                        (a) =>
                                                          a.pageId == page.id
                                                      )
                                                    ]
                                                      ? _vm._l(
                                                          _vm
                                                            .leftAnnotationList[
                                                            _vm.leftAnnotationList.findIndex(
                                                              (a) =>
                                                                a.pageId ==
                                                                page.id
                                                            )
                                                          ].annotationList,
                                                          function (
                                                            item,
                                                            aIdx
                                                          ) {
                                                            return _c(
                                                              "vue-draggable-resizable",
                                                              {
                                                                key: aIdx,
                                                                staticClass:
                                                                  "ocr-show-page-text",
                                                                style: {
                                                                  position:
                                                                    "absolute",
                                                                  border:
                                                                    "none",
                                                                },
                                                                attrs: {
                                                                  draggable:
                                                                    _vm.checkPermission(
                                                                      "addAnnotation:edit"
                                                                    ) &&
                                                                    _vm.changeAll &&
                                                                    _vm.isLocalDrag &&
                                                                    item.selected
                                                                      ? true
                                                                      : false,
                                                                  resizable: false,
                                                                  parent: true,
                                                                  x: _vm.getAnnotationStyle(
                                                                    item,
                                                                    _vm
                                                                      .showImgLeftListPostions[
                                                                      _vm.leftAnnotationList.findIndex(
                                                                        (a) =>
                                                                          a.pageId ==
                                                                          page.id
                                                                      )
                                                                    ],
                                                                    page,
                                                                    "left"
                                                                  ).x,
                                                                  y: _vm.getAnnotationStyle(
                                                                    item,
                                                                    _vm
                                                                      .showImgLeftListPostions[
                                                                      _vm.leftAnnotationList.findIndex(
                                                                        (a) =>
                                                                          a.pageId ==
                                                                          page.id
                                                                      )
                                                                    ],
                                                                    page,
                                                                    "left"
                                                                  ).y,
                                                                  w: _vm.getAnnotationStyle(
                                                                    item,
                                                                    _vm
                                                                      .showImgLeftListPostions[
                                                                      _vm.leftAnnotationList.findIndex(
                                                                        (a) =>
                                                                          a.pageId ==
                                                                          page.id
                                                                      )
                                                                    ],
                                                                    page,
                                                                    "left"
                                                                  ).w,
                                                                  h: _vm.getAnnotationStyle(
                                                                    item,
                                                                    _vm
                                                                      .showImgLeftListPostions[
                                                                      _vm.leftAnnotationList.findIndex(
                                                                        (a) =>
                                                                          a.pageId ==
                                                                          page.id
                                                                      )
                                                                    ],
                                                                    page,
                                                                    "left"
                                                                  ).h,
                                                                  z: item.selected
                                                                    ? 98
                                                                    : 97,
                                                                },
                                                                on: {
                                                                  activated:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.onActivatedAnnotation(
                                                                        item
                                                                      )
                                                                    },
                                                                  deactivated:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.onDeactivatedAnnotation(
                                                                        item
                                                                      )
                                                                    },
                                                                  resizestop: (
                                                                    left,
                                                                    top,
                                                                    width,
                                                                    height
                                                                  ) => {
                                                                    _vm.onResizstopAnnotation(
                                                                      left,
                                                                      top,
                                                                      width,
                                                                      height,
                                                                      item,
                                                                      page,
                                                                      _vm
                                                                        .showImgLeftListPostions[
                                                                        _vm.leftAnnotationList.findIndex(
                                                                          (a) =>
                                                                            a.pageId ==
                                                                            page.id
                                                                        )
                                                                      ],
                                                                      "left"
                                                                    )
                                                                  },
                                                                  dragstop: (
                                                                    left,
                                                                    top
                                                                  ) => {
                                                                    _vm.onDragstopAnnotation(
                                                                      left,
                                                                      top,
                                                                      item,
                                                                      page,
                                                                      _vm
                                                                        .showImgLeftListPostions[
                                                                        _vm.leftAnnotationList.findIndex(
                                                                          (a) =>
                                                                            a.pageId ==
                                                                            page.id
                                                                        )
                                                                      ],
                                                                      "left"
                                                                    )
                                                                  },
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    style:
                                                                      _vm.getActionStyle(
                                                                        _vm
                                                                          .showImgLeftListPostions[
                                                                          idx
                                                                        ],
                                                                        page
                                                                      ),
                                                                    attrs: {
                                                                      id: item.id
                                                                        ? "local-" +
                                                                          item.id
                                                                        : "newLocal-" +
                                                                          aIdx,
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "local-two",
                                                                      {
                                                                        attrs: {
                                                                          theme:
                                                                            "filled",
                                                                          size: item.size,
                                                                          fill: item.selected
                                                                            ? _vm.color
                                                                            : "orange",
                                                                          strokeWidth: 3,
                                                                        },
                                                                        on: {
                                                                          mouseover:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              _vm.isLocalDrag = true
                                                                            },
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                item.selected
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "local-content",
                                                                        style:
                                                                          _vm.getLocalActionStyle(
                                                                            _vm
                                                                              .showImgLeftListPostions[
                                                                              idx
                                                                            ],
                                                                            page,
                                                                            "left",
                                                                            item
                                                                          ),
                                                                        on: {
                                                                          mouseover:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              _vm.isLocalDrag = false
                                                                            },
                                                                          mouseleave:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              _vm.isLocalDrag = true
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        item
                                                                          .commentData
                                                                          .length
                                                                          ? _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "local-content-list",
                                                                              },
                                                                              _vm._l(
                                                                                item.commentData,
                                                                                function (
                                                                                  i,
                                                                                  cidx
                                                                                ) {
                                                                                  return _c(
                                                                                    "div",
                                                                                    {
                                                                                      key: cidx,
                                                                                      staticClass:
                                                                                        "local-content-item",
                                                                                      staticStyle:
                                                                                        {
                                                                                          "padding-left":
                                                                                            "5px",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "div",
                                                                                        {
                                                                                          staticClass:
                                                                                            "local-content-item-top",
                                                                                          staticStyle:
                                                                                            {
                                                                                              "padding-top":
                                                                                                "3px",
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "div",
                                                                                            {
                                                                                              staticClass:
                                                                                                "local-content-item-top-left",
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "img",
                                                                                                {
                                                                                                  attrs:
                                                                                                    {
                                                                                                      src: i.avatar,
                                                                                                      alt: "",
                                                                                                    },
                                                                                                }
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                          _c(
                                                                                            "div",
                                                                                            {
                                                                                              staticClass:
                                                                                                "local-content-item-top-right",
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "div",
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "\n                                    " +
                                                                                                      _vm._s(
                                                                                                        i.userName
                                                                                                      ) +
                                                                                                      "\n                                  "
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                              _c(
                                                                                                "div",
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "\n                                    " +
                                                                                                      _vm._s(
                                                                                                        i.createTime
                                                                                                      ) +
                                                                                                      "\n                                  "
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                      _c(
                                                                                        "div",
                                                                                        {
                                                                                          staticClass:
                                                                                            "local-content-item-bottom",
                                                                                          staticStyle:
                                                                                            {
                                                                                              "font-size":
                                                                                                "12px",
                                                                                            },
                                                                                          domProps:
                                                                                            {
                                                                                              innerHTML:
                                                                                                _vm._s(
                                                                                                  i.content
                                                                                                ),
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                }
                                                                              ),
                                                                              0
                                                                            )
                                                                          : _vm._e(),
                                                                        (_vm.checkPermission(
                                                                          "addAnnotation:edit"
                                                                        ) ||
                                                                          item
                                                                            .commentData[0]
                                                                            .userId ==
                                                                            _vm
                                                                              .userInfo
                                                                              .id) &&
                                                                        !item
                                                                          .commentData[0]
                                                                          ? _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "local-content-input",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "j-editor",
                                                                                  {
                                                                                    ref: "localLeftInput",
                                                                                    refInFor: true,
                                                                                    attrs:
                                                                                      {
                                                                                        placeholder:
                                                                                          "ALT+回车换行，回车发送",
                                                                                        maxWidth:
                                                                                          "50px",
                                                                                        toolbar:
                                                                                          [],
                                                                                      },
                                                                                    on: {
                                                                                      mouseover:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          $event.stopPropagation()
                                                                                          _vm.isLocalDrag = false
                                                                                        },
                                                                                      postValue:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.localEnter(
                                                                                            null,
                                                                                            item,
                                                                                            "left"
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                    model:
                                                                                      {
                                                                                        value:
                                                                                          _vm.localLeftValue,
                                                                                        callback:
                                                                                          function (
                                                                                            $$v
                                                                                          ) {
                                                                                            _vm.localLeftValue =
                                                                                              $$v
                                                                                          },
                                                                                        expression:
                                                                                          "localLeftValue",
                                                                                      },
                                                                                  }
                                                                                ),
                                                                              ],
                                                                              1
                                                                            )
                                                                          : _vm._e(),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                                (item.selected &&
                                                                  _vm.checkPermission(
                                                                    "addAnnotation:dele"
                                                                  )) ||
                                                                (item.commentData &&
                                                                  item
                                                                    .commentData[0] &&
                                                                  item
                                                                    .commentData[0]
                                                                    .userId ==
                                                                    _vm.userInfo
                                                                      .id) ||
                                                                (item.toStage ==
                                                                  "制作环节" &&
                                                                  (_vm
                                                                    .taskDetail
                                                                    .stageName ==
                                                                    "终审" ||
                                                                    _vm
                                                                      .taskDetail
                                                                      .stageName ==
                                                                      "翻译" ||
                                                                    _vm
                                                                      .taskDetail
                                                                      .stageName ==
                                                                      "校对"))
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "ocr-show-item-title-local",
                                                                        style:
                                                                          _vm.getActionCloseStyle(
                                                                            _vm
                                                                              .showImgLeftListPostions[
                                                                              idx
                                                                            ],
                                                                            page
                                                                          ),
                                                                        on: {
                                                                          mouseover:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              _vm.isLocalDrag = false
                                                                            },
                                                                          mouseleave:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              _vm.isLocalDrag = true
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span"
                                                                        ),
                                                                        (_vm.checkPermission(
                                                                          "addAnnotation:dele"
                                                                        ) ||
                                                                          (item.commentData &&
                                                                            item
                                                                              .commentData[0] &&
                                                                            item
                                                                              .commentData[0]
                                                                              .userId ==
                                                                              _vm
                                                                                .userInfo
                                                                                .id) ||
                                                                          (item.toStage ==
                                                                            "制作环节" &&
                                                                            (_vm
                                                                              .taskDetail
                                                                              .stageName ==
                                                                              "终审" ||
                                                                              _vm
                                                                                .taskDetail
                                                                                .stageName ==
                                                                                "翻译" ||
                                                                              _vm
                                                                                .taskDetail
                                                                                .stageName ==
                                                                                "校对"))) &&
                                                                        item.selected
                                                                          ? _c(
                                                                              "div",
                                                                              [
                                                                                _c(
                                                                                  "a-icon",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        type: "close",
                                                                                      },
                                                                                    on: {
                                                                                      click:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          _vm.deleteLocal(
                                                                                            _vm
                                                                                              .leftAnnotationList[
                                                                                              _vm.leftAnnotationList.findIndex(
                                                                                                (
                                                                                                  a
                                                                                                ) =>
                                                                                                  a.pageId ==
                                                                                                  page.id
                                                                                              )
                                                                                            ],
                                                                                            aIdx
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  }
                                                                                ),
                                                                              ],
                                                                              1
                                                                            )
                                                                          : _vm._e(),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            )
                                                          }
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  2
                                                )
                                              }
                                            ),
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                  ]
                                : _vm._e(),
                              _vm.showOptions.leftOptions.length > 1 &&
                              _vm.showOptions.leftOptions[1].checked
                                ? _vm._l(_vm.jpgListData, function (item, idx) {
                                    return _c("img", {
                                      key: item.id,
                                      ref: item.id + "showLeftImg",
                                      refInFor: true,
                                      staticClass: "left-show-img",
                                      style:
                                        "width:100%;height:auto;border-bottom:2px dashed " +
                                        (idx == _vm.jpgListData.length - 1
                                          ? "rgba(0,0,0,0)"
                                          : "orange"),
                                      attrs: { src: item.filePath, alt: "" },
                                      on: {
                                        dragstart: function ($event) {
                                          $event.preventDefault()
                                        },
                                      },
                                    })
                                  })
                                : _vm._e(),
                            ],
                            2
                          )
                        : _c("div", { staticClass: "no-permission" }, [
                            _c("span", [_vm._v("无权限")]),
                          ]),
                    ]
                  ),
                  _vm.rightImgShow
                    ? _c(
                        "div",
                        {
                          staticClass: "translate-bottom-left-show-icon",
                          style: `top:calc(50% - ${64 - _vm.scrollTop}px)`,
                          on: { click: _vm.changeLeftImgShow },
                        },
                        [
                          _vm.leftImgShow
                            ? _c("a-icon", { attrs: { type: "left" } })
                            : _c("a-icon", { attrs: { type: "right" } }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
                _c(
                  "a-menu",
                  { attrs: { slot: "overlay" }, slot: "overlay" },
                  [
                    _c(
                      "a-menu-item",
                      {
                        key: "1",
                        on: {
                          click: function ($event) {
                            return _vm.rightClick("black")
                          },
                        },
                      },
                      [_vm._v(" 黑色 ")]
                    ),
                    _c(
                      "a-menu-item",
                      {
                        key: "2",
                        on: {
                          click: function ($event) {
                            return _vm.rightClick("#fff")
                          },
                        },
                      },
                      [_vm._v(" 白色 ")]
                    ),
                    _c(
                      "a-menu-item",
                      {
                        key: "3",
                        on: {
                          click: function ($event) {
                            _vm.rightClick("rgb(223,220,221)")
                          },
                        },
                      },
                      [_vm._v(" 灰色 ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "a-dropdown",
              { attrs: { trigger: ["contextmenu"] } },
              [
                _c("div", { staticClass: "show-img-bottom-right" }, [
                  _c(
                    "div",
                    {
                      style: {
                        minHeight: "100%",
                        backgroundColor: _vm.backgroundColor,
                      },
                    },
                    [
                      _vm.checkPermission("showImgRight:view")
                        ? _c(
                            "div",
                            {
                              style: {
                                minHeight: "100%",
                                width: _vm.showImgListWidth,
                              },
                              on: {
                                contextmenu: function ($event) {
                                  $event.stopPropagation()
                                },
                              },
                            },
                            [
                              _vm._l(_vm.pageListData, function (item, idx) {
                                return _c("img", {
                                  key: item.id,
                                  ref: item.id + "showRightImg",
                                  refInFor: true,
                                  class: _vm.imgShow(
                                    item,
                                    _vm.showImgLeftListPostions[idx] ||
                                      _vm.showImgLeftListPostions[0]
                                  )
                                    ? "right-show-img"
                                    : "right-show-img right-show-img-blur",
                                  style:
                                    "width:100%;height:auto;border-bottom:2px dashed " +
                                    (idx == _vm.pageListData.length - 1
                                      ? "rgba(0,0,0,0)"
                                      : "orange"),
                                  attrs: { src: item.filePath, alt: "" },
                                  on: {
                                    dragstart: function ($event) {
                                      $event.preventDefault()
                                    },
                                  },
                                })
                              }),
                              _vm.pageListData.length &&
                              _vm.showImgLeftListPostions.length
                                ? _c(
                                    "div",
                                    {
                                      ref: "dragLineBox",
                                      staticClass: "ocr-show ocr-show-right",
                                      on: {
                                        click: _vm.ocrShowClick,
                                        mousedown: _vm.dragBoxmouseLinedown,
                                      },
                                    },
                                    [
                                      _vm.is_show_line_mask
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "line_mask",
                                              style: _vm.getLineMaskStyle(),
                                            },
                                            [
                                              _c("a-icon", {
                                                style: _vm.getArrowStyle(
                                                  _vm.start_x,
                                                  _vm.end_x,
                                                  _vm.end_y,
                                                  _vm.start_y,
                                                  _vm.mask_line_width,
                                                  _vm.mask_line_height
                                                ),
                                                attrs: { type: "caret-right" },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._l(
                                        _vm.pageListData,
                                        function (page, idx) {
                                          return _c(
                                            "div",
                                            {
                                              key: page.id,
                                              staticClass:
                                                "ocr-show-page ocr-show-page-right",
                                              style: {
                                                height:
                                                  _vm.showImgLeftListPostions[
                                                    idx
                                                  ] &&
                                                  _vm.showImgLeftListPostions[
                                                    idx
                                                  ].height + "px",
                                                overflow: _vm.rightImgShow
                                                  ? "initial"
                                                  : "hidden",
                                              },
                                              attrs: {
                                                id: page.id + "-right-" + idx,
                                              },
                                              on: {
                                                mousemove: (e) =>
                                                  _vm.disMovePage(e, page),
                                                mouseup: (e) =>
                                                  _vm.textMouseup(e, page),
                                                mousedown: function ($event) {
                                                  if (
                                                    $event.target !==
                                                    $event.currentTarget
                                                  )
                                                    return null
                                                  return _vm.clearSelect.apply(
                                                    null,
                                                    arguments
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm.selectTextAreas.length > 1 &&
                                              _vm.getDeleteBorder.idx === idx
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "delete-border",
                                                      style:
                                                        _vm.getDeleteBorder,
                                                      on: {
                                                        mousedown: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target !==
                                                            $event.currentTarget
                                                          )
                                                            return null
                                                          return _vm.clearSelect.apply(
                                                            null,
                                                            arguments
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        [
                                                          _c("a-icon", {
                                                            attrs: {
                                                              type: "close",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.stopPropagation()
                                                                return _vm.batchDeleteTextArea.apply(
                                                                  null,
                                                                  arguments
                                                                )
                                                              },
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._l(
                                                page.textAreas,
                                                function (item) {
                                                  return [
                                                    (_vm.textShow(
                                                      item,
                                                      _vm
                                                        .showImgLeftListPostions[
                                                        idx
                                                      ] ||
                                                        _vm
                                                          .showImgLeftListPostions[0],
                                                      page
                                                    ) ||
                                                      idx ==
                                                        _vm.pageListData
                                                          .length -
                                                          1) &&
                                                    (item.id + "").indexOf(
                                                      "new"
                                                    ) == -1 &&
                                                    _vm.rightImgShow &&
                                                    !item.imgUrl
                                                      ? _c(
                                                          "vue-draggable-resizable",
                                                          {
                                                            key: item.id,
                                                            staticClass:
                                                              "ocr-show-page-text",
                                                            style: `position: absolute; border: none;min-width:${
                                                              _vm
                                                                .showImgLeftListPostions[
                                                                idx
                                                              ]
                                                                ? (_vm
                                                                    .showImgLeftListPostions[
                                                                    idx
                                                                  ].width /
                                                                    page.width) *
                                                                  80
                                                                : "0"
                                                            }px;min-height:${
                                                              _vm
                                                                .showImgLeftListPostions[
                                                                idx
                                                              ]
                                                                ? (_vm
                                                                    .showImgLeftListPostions[
                                                                    idx
                                                                  ].width /
                                                                    page.width) *
                                                                  60
                                                                : "0"
                                                            }px`,
                                                            attrs: {
                                                              "v-model:active":
                                                                item
                                                                  .textAreaRegion
                                                                  .selected
                                                                  ? true
                                                                  : false,
                                                              parent: true,
                                                              resizable: false,
                                                              draggable:
                                                                item
                                                                  .translateTextAreaRegion
                                                                  .editble ||
                                                                _vm.isDelete
                                                                  ? false
                                                                  : _vm.checkPermission(
                                                                      "addTextArea:edit"
                                                                    ) &&
                                                                    _vm.changeAll &&
                                                                    item
                                                                      .textAreaRegion
                                                                      .selected
                                                                  ? true
                                                                  : false,
                                                              x: _vm.getItemStyle(
                                                                item,
                                                                _vm
                                                                  .showImgLeftListPostions[
                                                                  idx
                                                                ] ||
                                                                  _vm
                                                                    .showImgLeftListPostions[0],
                                                                page,
                                                                "right"
                                                              ).x,
                                                              y: _vm.getItemStyle(
                                                                item,
                                                                _vm
                                                                  .showImgLeftListPostions[
                                                                  idx
                                                                ] ||
                                                                  _vm
                                                                    .showImgLeftListPostions[0],
                                                                page,
                                                                "right"
                                                              ).y,
                                                              w: _vm.getItemStyle(
                                                                item,
                                                                _vm
                                                                  .showImgLeftListPostions[
                                                                  idx
                                                                ] ||
                                                                  _vm
                                                                    .showImgLeftListPostions[0],
                                                                page,
                                                                "right"
                                                              ).w,
                                                              h: _vm.getItemStyle(
                                                                item,
                                                                _vm
                                                                  .showImgLeftListPostions[
                                                                  idx
                                                                ] ||
                                                                  _vm
                                                                    .showImgLeftListPostions[0],
                                                                page,
                                                                "right"
                                                              ).h,
                                                              z: item
                                                                .textAreaRegion
                                                                .selected
                                                                ? 99
                                                                : 97,
                                                            },
                                                            on: {
                                                              activated:
                                                                function (
                                                                  $event
                                                                ) {
                                                                  return _vm.onActivated(
                                                                    item,
                                                                    "right",
                                                                    page
                                                                  )
                                                                },
                                                              deactivated:
                                                                function (
                                                                  $event
                                                                ) {
                                                                  return _vm.onDeactivated(
                                                                    item,
                                                                    "right",
                                                                    page
                                                                  )
                                                                },
                                                              resizestop:
                                                                function (
                                                                  $event
                                                                ) {
                                                                  return _vm.onResizstop(
                                                                    item,
                                                                    "right"
                                                                  )
                                                                },
                                                              dragstop: (
                                                                left,
                                                                top
                                                              ) =>
                                                                _vm.onDragstop(
                                                                  left,
                                                                  top,
                                                                  item,
                                                                  page,
                                                                  _vm
                                                                    .showImgLeftListPostions[
                                                                    idx
                                                                  ],
                                                                  "right"
                                                                ),
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                key:
                                                                  page.id +
                                                                  "-" +
                                                                  item.id +
                                                                  "-" +
                                                                  "right",
                                                                class: item
                                                                  .textAreaRegion
                                                                  .selected
                                                                  ? "ocr-show-item-selected ocr-show-item-right"
                                                                  : "ocr-show-item-right",
                                                                style: `background-color:${
                                                                  item.backgroundColor ||
                                                                  "#fff"
                                                                };-webkit-text-orientation:revert`,
                                                                attrs: {
                                                                  id:
                                                                    page.id +
                                                                    "-" +
                                                                    item.id +
                                                                    "-" +
                                                                    "right",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.stopPropagation()
                                                                      return ((
                                                                        e
                                                                      ) => {
                                                                        _vm.textAreaClick(
                                                                          e,
                                                                          item,
                                                                          page
                                                                        )
                                                                      }).apply(
                                                                        null,
                                                                        arguments
                                                                      )
                                                                    },
                                                                  dblclick: (
                                                                    e
                                                                  ) => {
                                                                    _vm.textAreaDbclick(
                                                                      e,
                                                                      item,
                                                                      page.id +
                                                                        "-" +
                                                                        item.id +
                                                                        "-" +
                                                                        "right"
                                                                    )
                                                                  },
                                                                  mousedown:
                                                                    _vm.textAreaMousedown,
                                                                  mousemove: (
                                                                    e
                                                                  ) =>
                                                                    _vm.rightMove(
                                                                      e,
                                                                      item
                                                                    ),
                                                                  mouseleave: (
                                                                    e
                                                                  ) =>
                                                                    _vm.leftOut(
                                                                      e,
                                                                      item
                                                                    ),
                                                                  mouseup: (
                                                                    e
                                                                  ) =>
                                                                    _vm.textMouseup(
                                                                      e,
                                                                      item,
                                                                      page
                                                                    ),
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    ref:
                                                                      page.id +
                                                                      "-" +
                                                                      item.id +
                                                                      "-" +
                                                                      "editDiv",
                                                                    refInFor: true,
                                                                    class:
                                                                      !_vm.isDownLoad
                                                                        ? "right-text-input right-text-input-edit"
                                                                        : "right-text-input",
                                                                    style: {
                                                                      minHeight:
                                                                        item
                                                                          .characterSentences
                                                                          .length ==
                                                                          1 &&
                                                                        item
                                                                          .characterSentences[0]
                                                                          .characterText ==
                                                                          ""
                                                                          ? item
                                                                              .characterSentences[0]
                                                                              .fontSize +
                                                                            30 +
                                                                            "px"
                                                                          : "",
                                                                      minWidth:
                                                                        item
                                                                          .characterSentences
                                                                          .length ==
                                                                          1 &&
                                                                        item
                                                                          .characterSentences[0]
                                                                          .characterText ==
                                                                          ""
                                                                          ? item
                                                                              .characterSentences[0]
                                                                              .fontSize +
                                                                            30 +
                                                                            "px"
                                                                          : "",
                                                                      writingMode:
                                                                        item.textLayout ==
                                                                        2
                                                                          ? "vertical-rl"
                                                                          : "horizontal-tb",
                                                                      textAlign:
                                                                        item
                                                                          .characterSentences[0]
                                                                          ? item
                                                                              .characterSentences[0]
                                                                              .textAlign
                                                                          : _vm
                                                                              .oldTextBatchStyle
                                                                              .textAlign,
                                                                      lineHeight:
                                                                        item
                                                                          .characterSentences[0]
                                                                          ? item.characterSentences[0].lineSpacing.toFixed(
                                                                              2
                                                                            )
                                                                          : _vm.oldTextBatchStyle.lineSpacing.toFixed(
                                                                              2
                                                                            ),
                                                                      letterSpacing:
                                                                        item.textLayout ==
                                                                        2
                                                                          ? _vm
                                                                              .oldTextBatchStyle
                                                                              .textHeight >
                                                                            1
                                                                            ? (10).toFixed(
                                                                                0
                                                                              ) +
                                                                              "px"
                                                                            : (1).toFixed(
                                                                                0
                                                                              ) +
                                                                              "px"
                                                                          : _vm
                                                                              .oldTextBatchStyle
                                                                              .textWidth >
                                                                            1
                                                                          ? (10).toFixed(
                                                                              0
                                                                            ) +
                                                                            "px"
                                                                          : (1).toFixed(
                                                                              0
                                                                            ) +
                                                                            "px",
                                                                      fontSize:
                                                                        item
                                                                          .characterSentences
                                                                          .length ==
                                                                          1 &&
                                                                        (item
                                                                          .characterSentences[0]
                                                                          .characterText ==
                                                                          "" ||
                                                                          item
                                                                            .characterSentences[0]
                                                                            .characterText ==
                                                                            " ")
                                                                          ? item
                                                                              .characterSentences[0]
                                                                              .fontSize +
                                                                            "px"
                                                                          : "",
                                                                      color:
                                                                        item
                                                                          .characterSentences
                                                                          .length ==
                                                                          1 &&
                                                                        (item
                                                                          .characterSentences[0]
                                                                          .characterText ==
                                                                          "" ||
                                                                          item
                                                                            .characterSentences[0]
                                                                            .characterText ==
                                                                            " ")
                                                                          ? item
                                                                              .characterSentences[0]
                                                                              .fontColor
                                                                          : "",
                                                                      backgroundColor:
                                                                        item.backgroundColor ||
                                                                        "#fff",
                                                                      padding:
                                                                        _vm.getPadding(
                                                                          item
                                                                        ),
                                                                      position:
                                                                        "relative",
                                                                      left: _vm.getInputStyle(
                                                                        item,
                                                                        page,
                                                                        idx,
                                                                        _vm
                                                                          .showImgLeftListPostions[
                                                                          idx
                                                                        ]
                                                                      ),
                                                                    },
                                                                    attrs: {
                                                                      role: "textarea",
                                                                      contenteditable:
                                                                        item
                                                                          .translateTextAreaRegion
                                                                          .editble
                                                                          ? true
                                                                          : false,
                                                                      id:
                                                                        page.id +
                                                                        "-" +
                                                                        item.id +
                                                                        "-" +
                                                                        "editDiv",
                                                                    },
                                                                    on: {
                                                                      keyup: (
                                                                        e
                                                                      ) =>
                                                                        _vm.textChange(
                                                                          e,
                                                                          item
                                                                        ),
                                                                      keydown: (
                                                                        e
                                                                      ) =>
                                                                        _vm.textDownChange(
                                                                          e,
                                                                          item
                                                                        ),
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.textClick(
                                                                            item,
                                                                            "right",
                                                                            page
                                                                          )
                                                                        },
                                                                      input: (
                                                                        e
                                                                      ) =>
                                                                        _vm.textInput(
                                                                          e,
                                                                          item,
                                                                          page
                                                                        ),
                                                                      mousemove:
                                                                        (e) =>
                                                                          _vm.disMove(
                                                                            e,
                                                                            item
                                                                          ),
                                                                      blur: function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.textBlur(
                                                                          item,
                                                                          "right",
                                                                          page
                                                                        )
                                                                      },
                                                                      paste: (
                                                                        e
                                                                      ) =>
                                                                        _vm.handlePaste(
                                                                          e,
                                                                          item
                                                                        ),
                                                                      copy: (
                                                                        e
                                                                      ) =>
                                                                        _vm.handleCopy(
                                                                          e,
                                                                          item
                                                                        ),
                                                                    },
                                                                  },
                                                                  _vm._l(
                                                                    item.showTexts,
                                                                    function (
                                                                      p
                                                                    ) {
                                                                      return _c(
                                                                        "p",
                                                                        {
                                                                          key:
                                                                            page.id +
                                                                            "-" +
                                                                            item.id +
                                                                            "-" +
                                                                            p.pId,
                                                                          staticStyle:
                                                                            {
                                                                              margin:
                                                                                "0",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              id:
                                                                                page.id +
                                                                                "-" +
                                                                                item.id +
                                                                                "-" +
                                                                                p.pId,
                                                                            },
                                                                        },
                                                                        _vm._l(
                                                                          p.texts,
                                                                          function (
                                                                            text,
                                                                            textIdx
                                                                          ) {
                                                                            return _c(
                                                                              "span",
                                                                              {
                                                                                key: text.characterSentenceId,
                                                                                attrs:
                                                                                  {
                                                                                    id:
                                                                                      page.id +
                                                                                      "-" +
                                                                                      item.id +
                                                                                      "-" +
                                                                                      text.characterSentenceId,
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "span",
                                                                                  {
                                                                                    class:
                                                                                      text.hasMark &&
                                                                                      item.textLayout ==
                                                                                        1
                                                                                        ? "stroke-span stroke-span-hasMark"
                                                                                        : "stroke-span",
                                                                                    style:
                                                                                      _vm.getTextStyle(
                                                                                        text,
                                                                                        _vm
                                                                                          .showImgLeftListPostions[
                                                                                          idx
                                                                                        ] ||
                                                                                          _vm
                                                                                            .showImgLeftListPostions[0],
                                                                                        page,
                                                                                        item
                                                                                      ),
                                                                                    attrs:
                                                                                      {
                                                                                        "data-text":
                                                                                          text.phonetic &&
                                                                                          text
                                                                                            .phonetic
                                                                                            .phoneticText,
                                                                                        id: text.characterSentenceId,
                                                                                      },
                                                                                    on: {
                                                                                      mousedown:
                                                                                        (
                                                                                          e
                                                                                        ) =>
                                                                                          _vm.characterClickBefore(
                                                                                            e,
                                                                                            item,
                                                                                            page.id +
                                                                                              "-" +
                                                                                              item.id +
                                                                                              "-" +
                                                                                              "right"
                                                                                          ),
                                                                                      mousemove:
                                                                                        (
                                                                                          e
                                                                                        ) =>
                                                                                          _vm.characterClick(
                                                                                            e,
                                                                                            item
                                                                                          ),
                                                                                    },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "span",
                                                                                      {
                                                                                        staticClass:
                                                                                          "stroke-span-child",
                                                                                        style:
                                                                                          "white-space: pre-wrap;" +
                                                                                          `transform:scale(${text.textWidth},${text.textHeight});` +
                                                                                          `${
                                                                                            item.textLayout ==
                                                                                              2 &&
                                                                                            text.characterText ==
                                                                                              " "
                                                                                              ? `height:${
                                                                                                  text.fontSize /
                                                                                                  3
                                                                                                }px;`
                                                                                              : ""
                                                                                          }` +
                                                                                          `${
                                                                                            text.characterText ==
                                                                                              " " &&
                                                                                            !textIdx &&
                                                                                            (!_vm.inputStart ||
                                                                                              !item
                                                                                                .translateTextAreaRegion
                                                                                                .editble)
                                                                                              ? "display:inline-block;width:0;height:0;"
                                                                                              : ""
                                                                                          }` +
                                                                                          `-webkit-text-stroke: ${
                                                                                            text.strokeWidth *
                                                                                            2
                                                                                          }px ${
                                                                                            text.strokeColor
                                                                                          };user-select:none;background-color:${
                                                                                            item
                                                                                              .textAreaRegion
                                                                                              .selected &&
                                                                                            text.isBlack
                                                                                              ? item.backgroundColor ==
                                                                                                "#ffff3a"
                                                                                                ? "#fff"
                                                                                                : "yellow"
                                                                                              : ""
                                                                                          };color: ${
                                                                                            item
                                                                                              .textAreaRegion
                                                                                              .selected &&
                                                                                            text.isYahoo
                                                                                              ? "red"
                                                                                              : ""
                                                                                          };`,
                                                                                        attrs:
                                                                                          {
                                                                                            contenteditable:
                                                                                              item
                                                                                                .translateTextAreaRegion
                                                                                                .editble
                                                                                                ? true
                                                                                                : false,
                                                                                            id:
                                                                                              text.characterSentenceId +
                                                                                              "-" +
                                                                                              "text",
                                                                                            "data-text":
                                                                                              text.strokeWidth &&
                                                                                              text.characterText !=
                                                                                                ""
                                                                                                ? text.characterText
                                                                                                : "",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            text.characterText !=
                                                                                              ""
                                                                                              ? text.characterText
                                                                                              : ""
                                                                                          )
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            )
                                                                          }
                                                                        ),
                                                                        0
                                                                      )
                                                                    }
                                                                  ),
                                                                  0
                                                                ),
                                                                item
                                                                  .textAreaRegion
                                                                  .selected
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "ocr-show-item-title",
                                                                        staticStyle:
                                                                          {
                                                                            opacity:
                                                                              "0.4",
                                                                          },
                                                                        on: {
                                                                          mousemove:
                                                                            _vm.stopMouseMove,
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                item.sequenceNo
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          [
                                                                            _c(
                                                                              "a-icon",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    type: "close",
                                                                                  },
                                                                                on: {
                                                                                  click:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.deleteTextArea(
                                                                                        page,
                                                                                        item
                                                                                      )
                                                                                    },
                                                                                },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    (_vm.textShow(
                                                      item,
                                                      _vm
                                                        .showImgLeftListPostions[
                                                        idx
                                                      ] ||
                                                        _vm
                                                          .showImgLeftListPostions[0],
                                                      page
                                                    ) ||
                                                      idx ==
                                                        _vm.pageListData
                                                          .length -
                                                          1) &&
                                                    _vm.rightImgShow &&
                                                    item.imgUrl
                                                      ? _c(
                                                          "vue-draggable-resizable",
                                                          {
                                                            key: item.id,
                                                            staticClass:
                                                              "ocr-show-page-text",
                                                            style: `position: absolute; border: none;height:max-content;min-height:10px;`,
                                                            attrs: {
                                                              "v-model:active":
                                                                item
                                                                  .textAreaRegion
                                                                  .selected
                                                                  ? true
                                                                  : false,
                                                              parent: true,
                                                              resizable:
                                                                _vm.checkPermission(
                                                                  "addTextArea:edit"
                                                                ) &&
                                                                _vm.taskStatus ==
                                                                  "可开始"
                                                                  ? true
                                                                  : false,
                                                              draggable:
                                                                _vm.isDelete
                                                                  ? false
                                                                  : _vm.checkPermission(
                                                                      "addTextArea:edit"
                                                                    ) &&
                                                                    _vm.changeAll &&
                                                                    item
                                                                      .textAreaRegion
                                                                      .selected
                                                                  ? true
                                                                  : false,
                                                              x: _vm.getItemImgStyle(
                                                                item,
                                                                _vm
                                                                  .showImgLeftListPostions[
                                                                  idx
                                                                ] ||
                                                                  _vm
                                                                    .showImgLeftListPostions[0],
                                                                page,
                                                                "right"
                                                              ).x,
                                                              y: _vm.getItemImgStyle(
                                                                item,
                                                                _vm
                                                                  .showImgLeftListPostions[
                                                                  idx
                                                                ] ||
                                                                  _vm
                                                                    .showImgLeftListPostions[0],
                                                                page,
                                                                "right"
                                                              ).y,
                                                              w: _vm.getItemImgStyle(
                                                                item,
                                                                _vm
                                                                  .showImgLeftListPostions[
                                                                  idx
                                                                ] ||
                                                                  _vm
                                                                    .showImgLeftListPostions[0],
                                                                page,
                                                                "right"
                                                              ).w,
                                                              h: _vm.getItemImgStyle(
                                                                item,
                                                                _vm
                                                                  .showImgLeftListPostions[
                                                                  idx
                                                                ] ||
                                                                  _vm
                                                                    .showImgLeftListPostions[0],
                                                                page,
                                                                "right"
                                                              ).h,
                                                              z: item
                                                                .textAreaRegion
                                                                .selected
                                                                ? 99
                                                                : 97,
                                                            },
                                                            on: {
                                                              activated:
                                                                function (
                                                                  $event
                                                                ) {
                                                                  return _vm.onActivated(
                                                                    item,
                                                                    "right",
                                                                    page
                                                                  )
                                                                },
                                                              deactivated:
                                                                function (
                                                                  $event
                                                                ) {
                                                                  return _vm.onDeactivated(
                                                                    item,
                                                                    "right",
                                                                    page
                                                                  )
                                                                },
                                                              resizestop:
                                                                function (
                                                                  $event
                                                                ) {
                                                                  return _vm.onResizstop(
                                                                    item,
                                                                    "right"
                                                                  )
                                                                },
                                                              dragstop: (
                                                                left,
                                                                top
                                                              ) =>
                                                                _vm.onDragstop(
                                                                  left,
                                                                  top,
                                                                  item,
                                                                  page,
                                                                  _vm
                                                                    .showImgLeftListPostions[
                                                                    idx
                                                                  ],
                                                                  "left"
                                                                ),
                                                              resizing: (
                                                                left,
                                                                top,
                                                                width,
                                                                height
                                                              ) => {
                                                                _vm.onImgResizing(
                                                                  left,
                                                                  top,
                                                                  width,
                                                                  height,
                                                                  item,
                                                                  page,
                                                                  _vm
                                                                    .showImgLeftListPostions[
                                                                    idx
                                                                  ]
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                key:
                                                                  page.id +
                                                                  "-" +
                                                                  item.id +
                                                                  "-" +
                                                                  "right",
                                                                class: item
                                                                  .textAreaRegion
                                                                  .selected
                                                                  ? "ocr-show-item-selected ocr-show-item-right"
                                                                  : "ocr-show-item-right",
                                                                style: `width:100%;height:100%;background-color:${
                                                                  item.backgroundColor ||
                                                                  "#fff"
                                                                };-webkit-text-orientation:revert`,
                                                                attrs: {
                                                                  id:
                                                                    page.id +
                                                                    "-" +
                                                                    item.id +
                                                                    "-" +
                                                                    "right",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.stopPropagation()
                                                                      return ((
                                                                        e
                                                                      ) => {
                                                                        _vm.textAreaClick(
                                                                          e,
                                                                          item,
                                                                          page
                                                                        )
                                                                      }).apply(
                                                                        null,
                                                                        arguments
                                                                      )
                                                                    },
                                                                  mousedown:
                                                                    _vm.textAreaMousedown,
                                                                  mousemove: (
                                                                    e
                                                                  ) =>
                                                                    _vm.rightMove(
                                                                      e,
                                                                      item
                                                                    ),
                                                                  mouseleave: (
                                                                    e
                                                                  ) =>
                                                                    _vm.leftOut(
                                                                      e,
                                                                      item
                                                                    ),
                                                                  mouseup: (
                                                                    e
                                                                  ) =>
                                                                    _vm.textMouseup(
                                                                      e,
                                                                      item,
                                                                      page
                                                                    ),
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    ref:
                                                                      page.id +
                                                                      "-" +
                                                                      item.id +
                                                                      "-" +
                                                                      "editDiv",
                                                                    refInFor: true,
                                                                    style: {
                                                                      width:
                                                                        "100%",
                                                                      height:
                                                                        "100%",
                                                                      backgroundColor:
                                                                        item.backgroundColor ||
                                                                        "rgba(0,0,0,0)",
                                                                      position:
                                                                        "relative",
                                                                      left: "0",
                                                                    },
                                                                    attrs: {
                                                                      id:
                                                                        page.id +
                                                                        "-" +
                                                                        item.id +
                                                                        "-" +
                                                                        "editDiv",
                                                                    },
                                                                    on: {
                                                                      keyup: (
                                                                        e
                                                                      ) =>
                                                                        _vm.textChange(
                                                                          e,
                                                                          item
                                                                        ),
                                                                      keydown: (
                                                                        e
                                                                      ) =>
                                                                        _vm.textDownChange(
                                                                          e,
                                                                          item
                                                                        ),
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.textClick(
                                                                            item,
                                                                            "right",
                                                                            page
                                                                          )
                                                                        },
                                                                      input: (
                                                                        e
                                                                      ) =>
                                                                        _vm.textInput(
                                                                          e,
                                                                          item,
                                                                          page
                                                                        ),
                                                                      mousemove:
                                                                        (e) =>
                                                                          _vm.disMove(
                                                                            e,
                                                                            item
                                                                          ),
                                                                      blur: function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.textBlur(
                                                                          item,
                                                                          "right",
                                                                          page
                                                                        )
                                                                      },
                                                                      paste: (
                                                                        e
                                                                      ) =>
                                                                        _vm.handlePaste(
                                                                          e,
                                                                          item
                                                                        ),
                                                                      copy: (
                                                                        e
                                                                      ) =>
                                                                        _vm.handleCopy(
                                                                          e,
                                                                          item
                                                                        ),
                                                                    },
                                                                  },
                                                                  [
                                                                    _c("img", {
                                                                      staticStyle:
                                                                        {
                                                                          width:
                                                                            "100%",
                                                                          height:
                                                                            "auto",
                                                                        },
                                                                      attrs: {
                                                                        src: item.imgUrl,
                                                                      },
                                                                    }),
                                                                  ]
                                                                ),
                                                                item
                                                                  .textAreaRegion
                                                                  .selected
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "ocr-show-item-title",
                                                                        staticStyle:
                                                                          {
                                                                            opacity:
                                                                              "0.4",
                                                                          },
                                                                        on: {
                                                                          mousemove:
                                                                            _vm.stopMouseMove,
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                item.sequenceNo
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          [
                                                                            _c(
                                                                              "a-icon",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    type: "close",
                                                                                  },
                                                                                on: {
                                                                                  click:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.deleteTextArea(
                                                                                        page,
                                                                                        item
                                                                                      )
                                                                                    },
                                                                                },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                }
                                              ),
                                              _vm.rightAnnotationList.length &&
                                              _vm.rightImgShow &&
                                              _vm.rightAnnotationList[
                                                _vm.rightAnnotationList.findIndex(
                                                  (a) => a.pageId == page.id
                                                )
                                              ]
                                                ? _vm._l(
                                                    _vm.rightAnnotationList[
                                                      _vm.rightAnnotationList.findIndex(
                                                        (a) =>
                                                          a.pageId == page.id
                                                      )
                                                    ].annotationList,
                                                    function (item, aIdx) {
                                                      return _c(
                                                        "vue-draggable-resizable",
                                                        {
                                                          key:
                                                            aIdx + "imgLocal",
                                                          staticClass:
                                                            "ocr-show-page-text",
                                                          style: {
                                                            position:
                                                              "absolute",
                                                            border: "none",
                                                          },
                                                          attrs: {
                                                            draggable:
                                                              _vm.checkPermission(
                                                                "addAnnotation:edit"
                                                              ) &&
                                                              _vm.changeAll &&
                                                              _vm.isLocalDrag &&
                                                              item.selected
                                                                ? true
                                                                : false,
                                                            resizable: false,
                                                            parent: true,
                                                            x: _vm.getAnnotationStyle(
                                                              item,
                                                              _vm
                                                                .showImgLeftListPostions[
                                                                _vm.rightAnnotationList.findIndex(
                                                                  (a) =>
                                                                    a.pageId ==
                                                                    page.id
                                                                )
                                                              ],
                                                              page,
                                                              "right"
                                                            ).x,
                                                            y: _vm.getAnnotationStyle(
                                                              item,
                                                              _vm
                                                                .showImgLeftListPostions[
                                                                _vm.rightAnnotationList.findIndex(
                                                                  (a) =>
                                                                    a.pageId ==
                                                                    page.id
                                                                )
                                                              ],
                                                              page,
                                                              "right"
                                                            ).y,
                                                            w: _vm.getAnnotationStyle(
                                                              item,
                                                              _vm
                                                                .showImgLeftListPostions[
                                                                _vm.rightAnnotationList.findIndex(
                                                                  (a) =>
                                                                    a.pageId ==
                                                                    page.id
                                                                )
                                                              ],
                                                              page,
                                                              "right"
                                                            ).w,
                                                            h: _vm.getAnnotationStyle(
                                                              item,
                                                              _vm
                                                                .showImgLeftListPostions[
                                                                _vm.rightAnnotationList.findIndex(
                                                                  (a) =>
                                                                    a.pageId ==
                                                                    page.id
                                                                )
                                                              ],
                                                              page,
                                                              "right"
                                                            ).h,
                                                            z: item.selected
                                                              ? 98
                                                              : 97,
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                            },
                                                            activated:
                                                              function (
                                                                $event
                                                              ) {
                                                                return _vm.onActivatedAnnotation(
                                                                  item
                                                                )
                                                              },
                                                            deactivated:
                                                              function (
                                                                $event
                                                              ) {
                                                                return _vm.onDeactivatedAnnotation(
                                                                  item
                                                                )
                                                              },
                                                            resizestop: (
                                                              left,
                                                              top,
                                                              width,
                                                              height
                                                            ) => {
                                                              _vm.onResizstopAnnotation(
                                                                left,
                                                                top,
                                                                width,
                                                                height,
                                                                item,
                                                                page,
                                                                _vm
                                                                  .showImgLeftListPostions[
                                                                  _vm.rightAnnotationList.findIndex(
                                                                    (a) =>
                                                                      a.pageId ==
                                                                      page.id
                                                                  )
                                                                ],
                                                                "right"
                                                              )
                                                            },
                                                            dragging: (
                                                              left,
                                                              top
                                                            ) => {
                                                              _vm.onDraggingAnnotation(
                                                                left,
                                                                top,
                                                                item,
                                                                page,
                                                                _vm
                                                                  .showImgLeftListPostions[
                                                                  _vm.rightAnnotationList.findIndex(
                                                                    (a) =>
                                                                      a.pageId ==
                                                                      page.id
                                                                  )
                                                                ]
                                                              )
                                                            },
                                                            dragstop: (
                                                              left,
                                                              top
                                                            ) => {
                                                              _vm.onDragstopAnnotation(
                                                                left,
                                                                top,
                                                                item,
                                                                page,
                                                                _vm
                                                                  .showImgLeftListPostions[
                                                                  _vm.rightAnnotationList.findIndex(
                                                                    (a) =>
                                                                      a.pageId ==
                                                                      page.id
                                                                  )
                                                                ],
                                                                "right"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              style:
                                                                _vm.getActionStyle(
                                                                  _vm
                                                                    .showImgLeftListPostions[
                                                                    idx
                                                                  ],
                                                                  page
                                                                ),
                                                              attrs: {
                                                                id: item.id
                                                                  ? "local-" +
                                                                    item.id
                                                                  : "newLocal-" +
                                                                    aIdx,
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    $event.stopPropagation()
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c("local-two", {
                                                                attrs: {
                                                                  theme:
                                                                    "filled",
                                                                  size: item.size,
                                                                  fill: item.selected
                                                                    ? _vm.color
                                                                    : "orange",
                                                                  strokeWidth: 3,
                                                                },
                                                                on: {
                                                                  mouseover:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      _vm.isLocalDrag = true
                                                                    },
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                          item.selected
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "local-content",
                                                                  style:
                                                                    _vm.getLocalActionStyle(
                                                                      _vm
                                                                        .showImgLeftListPostions[
                                                                        idx
                                                                      ],
                                                                      page,
                                                                      "right",
                                                                      item
                                                                    ),
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        $event.stopPropagation()
                                                                      },
                                                                    mouseover:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        $event.stopPropagation()
                                                                        _vm.isLocalDrag = false
                                                                      },
                                                                    mouseleave:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        $event.stopPropagation()
                                                                        _vm.isLocalDrag = true
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "local-content-to-stage",
                                                                      attrs: {
                                                                        id: "local-content-to-stage",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              "font-size":
                                                                                "16px",
                                                                              height:
                                                                                "100%",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                            To:\n                          "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              "margin-left":
                                                                                "10px",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "a-radio-group",
                                                                            {
                                                                              on: {
                                                                                change:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.toStageChange(
                                                                                      item
                                                                                    )
                                                                                  },
                                                                              },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    item.toStage,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        item,
                                                                                        "toStage",
                                                                                        $$v
                                                                                      )
                                                                                    },
                                                                                  expression:
                                                                                    "item.toStage",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "a-radio",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      value:
                                                                                        "翻译环节",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                翻译环节\n                              "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "a-radio",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      value:
                                                                                        "制作环节",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                制作环节\n                              "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  item
                                                                    .commentData
                                                                    .length
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "local-content-list",
                                                                        },
                                                                        _vm._l(
                                                                          item.commentData,
                                                                          function (
                                                                            i,
                                                                            cidx
                                                                          ) {
                                                                            return _c(
                                                                              "div",
                                                                              {
                                                                                key: cidx,
                                                                                staticClass:
                                                                                  "local-content-item",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "local-content-item-top",
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "local-content-item-top-left",
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "img",
                                                                                          {
                                                                                            attrs:
                                                                                              {
                                                                                                src: i.avatar,
                                                                                                alt: "",
                                                                                              },
                                                                                          }
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "local-content-item-top-right",
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "div",
                                                                                          [
                                                                                            _vm._v(
                                                                                              "\n                                  " +
                                                                                                _vm._s(
                                                                                                  i.userName
                                                                                                ) +
                                                                                                "\n                                "
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                        _c(
                                                                                          "div",
                                                                                          [
                                                                                            _vm._v(
                                                                                              "\n                                  " +
                                                                                                _vm._s(
                                                                                                  i.createTime
                                                                                                ) +
                                                                                                "\n                                "
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "local-content-item-bottom",
                                                                                    domProps:
                                                                                      {
                                                                                        innerHTML:
                                                                                          _vm._s(
                                                                                            i.content
                                                                                          ),
                                                                                      },
                                                                                  }
                                                                                ),
                                                                              ]
                                                                            )
                                                                          }
                                                                        ),
                                                                        0
                                                                      )
                                                                    : _vm._e(),
                                                                  (_vm.checkPermission(
                                                                    "addAnnotation:edit"
                                                                  ) ||
                                                                    (item.commentData &&
                                                                      item
                                                                        .commentData[0] &&
                                                                      item
                                                                        .commentData[0]
                                                                        .userId ==
                                                                        _vm
                                                                          .userInfo
                                                                          .id)) &&
                                                                  !item
                                                                    .commentData[0]
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "local-content-input",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "j-editor",
                                                                            {
                                                                              ref: "localRightInput",
                                                                              refInFor: true,
                                                                              attrs:
                                                                                {
                                                                                  placeholder:
                                                                                    "ALT+回车换行，回车发送",
                                                                                  maxWidth:
                                                                                    "50px",
                                                                                  toolbar:
                                                                                    [],
                                                                                },
                                                                              on: {
                                                                                mouseover:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    $event.stopPropagation()
                                                                                    _vm.isLocalDrag = false
                                                                                  },
                                                                                postValue:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.localEnter(
                                                                                      null,
                                                                                      item,
                                                                                      "right"
                                                                                    )
                                                                                  },
                                                                              },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    _vm.localRightValue,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.localRightValue =
                                                                                        $$v
                                                                                    },
                                                                                  expression:
                                                                                    "localRightValue",
                                                                                },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _vm._e(),
                                                                  item
                                                                    .commentData[0]
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "local-content-input",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "j-editor",
                                                                            {
                                                                              ref: "localRightInput",
                                                                              refInFor: true,
                                                                              attrs:
                                                                                {
                                                                                  placeholder:
                                                                                    "ALT+回车换行，回车发送",
                                                                                  maxWidth:
                                                                                    "50px",
                                                                                  toolbar:
                                                                                    [],
                                                                                },
                                                                              on: {
                                                                                mouseover:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    $event.stopPropagation()
                                                                                    _vm.isLocalDrag = false
                                                                                  },
                                                                                postValue:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.pushLoacal(
                                                                                      item
                                                                                    )
                                                                                  },
                                                                              },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    _vm.postValue,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.postValue =
                                                                                        $$v
                                                                                    },
                                                                                  expression:
                                                                                    "postValue",
                                                                                },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          (item.selected &&
                                                            _vm.checkPermission(
                                                              "addAnnotation:dele"
                                                            )) ||
                                                          (item.commentData &&
                                                            item
                                                              .commentData[0] &&
                                                            item.commentData[0]
                                                              .userId ==
                                                              _vm.userInfo
                                                                .id) ||
                                                          (item.toStage ==
                                                            "制作环节" &&
                                                            (_vm.taskDetail
                                                              .stageName ==
                                                              "终审" ||
                                                              _vm.taskDetail
                                                                .stageName ==
                                                                "翻译" ||
                                                              _vm.taskDetail
                                                                .stageName ==
                                                                "校对"))
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "ocr-show-item-title-local",
                                                                  style:
                                                                    _vm.getActionCloseStyle(
                                                                      _vm
                                                                        .showImgLeftListPostions[
                                                                        idx
                                                                      ],
                                                                      page
                                                                    ),
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        $event.stopPropagation()
                                                                      },
                                                                    mouseover:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        $event.stopPropagation()
                                                                        _vm.isLocalDrag = false
                                                                      },
                                                                    mouseleave:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        $event.stopPropagation()
                                                                        _vm.isLocalDrag = true
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c("span"),
                                                                  (_vm.checkPermission(
                                                                    "addAnnotation:dele"
                                                                  ) ||
                                                                    (item.commentData &&
                                                                      item
                                                                        .commentData[0] &&
                                                                      item
                                                                        .commentData[0]
                                                                        .userId ==
                                                                        _vm
                                                                          .userInfo
                                                                          .id) ||
                                                                    (item.toStage ==
                                                                      "制作环节" &&
                                                                      (_vm
                                                                        .taskDetail
                                                                        .stageName ==
                                                                        "终审" ||
                                                                        _vm
                                                                          .taskDetail
                                                                          .stageName ==
                                                                          "翻译" ||
                                                                        _vm
                                                                          .taskDetail
                                                                          .stageName ==
                                                                          "校对"))) &&
                                                                  item.selected
                                                                    ? _c(
                                                                        "div",
                                                                        [
                                                                          _c(
                                                                            "a-icon",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  type: "close",
                                                                                },
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    _vm.deleteLocal(
                                                                                      _vm
                                                                                        .rightAnnotationList[
                                                                                        _vm.rightAnnotationList.findIndex(
                                                                                          (
                                                                                            a
                                                                                          ) =>
                                                                                            a.pageId ==
                                                                                            page.id
                                                                                        )
                                                                                      ],
                                                                                      aIdx
                                                                                    )
                                                                                  },
                                                                              },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      )
                                                    }
                                                  )
                                                : _vm._e(),
                                              _vm._l(
                                                page.lines,
                                                function (item) {
                                                  return [
                                                    (_vm.lineShow(
                                                      item,
                                                      _vm
                                                        .showImgLeftListPostions[
                                                        idx
                                                      ] ||
                                                        _vm
                                                          .showImgLeftListPostions[0],
                                                      page
                                                    ) ||
                                                      idx ==
                                                        _vm.pageListData
                                                          .length -
                                                          1) &&
                                                    (item.id + "").indexOf(
                                                      "new"
                                                    ) == -1 &&
                                                    _vm.rightImgShow
                                                      ? _c("SvgLine", {
                                                          key: item.id,
                                                          ref:
                                                            "page-line-" +
                                                            item.id,
                                                          refInFor: true,
                                                          attrs: {
                                                            startX:
                                                              item.startPosition
                                                                .x,
                                                            startY:
                                                              item.startPosition
                                                                .y,
                                                            endX: item
                                                              .endPosition.x,
                                                            endY: item
                                                              .endPosition.y,
                                                            width:
                                                              _vm.getLineItemStyle(
                                                                item,
                                                                _vm
                                                                  .showImgLeftListPostions[
                                                                  idx
                                                                ] ||
                                                                  _vm
                                                                    .showImgLeftListPostions[0],
                                                                page
                                                              ).w,
                                                            height:
                                                              _vm.getLineItemStyle(
                                                                item,
                                                                _vm
                                                                  .showImgLeftListPostions[
                                                                  idx
                                                                ] ||
                                                                  _vm
                                                                    .showImgLeftListPostions[0],
                                                                page
                                                              ).h,
                                                            x: _vm.getLineItemStyle(
                                                              item,
                                                              _vm
                                                                .showImgLeftListPostions[
                                                                idx
                                                              ] ||
                                                                _vm
                                                                  .showImgLeftListPostions[0],
                                                              page
                                                            ).x,
                                                            y: _vm.getLineItemStyle(
                                                              item,
                                                              _vm
                                                                .showImgLeftListPostions[
                                                                idx
                                                              ] ||
                                                                _vm
                                                                  .showImgLeftListPostions[0],
                                                              page
                                                            ).y,
                                                          },
                                                          on: {
                                                            lineMouseDwon: (
                                                              e
                                                            ) =>
                                                              _vm.lineMouseDwon(
                                                                e,
                                                                item,
                                                                page
                                                              ),
                                                            lineStartMousedown:
                                                              (e) =>
                                                                _vm.lineStartMousedown(
                                                                  e,
                                                                  item,
                                                                  page
                                                                ),
                                                            lineEndMousedown: (
                                                              e
                                                            ) =>
                                                              _vm.lineEndMousedown(
                                                                e,
                                                                item,
                                                                page
                                                              ),
                                                            activated:
                                                              function (
                                                                $event
                                                              ) {
                                                                return _vm.onLineActivated(
                                                                  item,
                                                                  page
                                                                )
                                                              },
                                                            deactivated:
                                                              function (
                                                                $event
                                                              ) {
                                                                return _vm.onLineDeactivated(
                                                                  item,
                                                                  page
                                                                )
                                                              },
                                                          },
                                                        })
                                                      : _vm._e(),
                                                  ]
                                                }
                                              ),
                                            ],
                                            2
                                          )
                                        }
                                      ),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                            ],
                            2
                          )
                        : _c("div", { staticClass: "no-permission" }, [
                            _c("span", [_vm._v("无权限")]),
                          ]),
                    ]
                  ),
                  _vm.leftImgShow
                    ? _c(
                        "div",
                        {
                          staticClass: "translate-bottom-right-show-icon",
                          style: `top:calc(50% - ${64 - _vm.scrollTop}px)`,
                          on: { click: _vm.changeRightImgShow },
                        },
                        [
                          _vm.rightImgShow
                            ? _c("a-icon", { attrs: { type: "right" } })
                            : _c("a-icon", { attrs: { type: "left" } }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
                _c(
                  "a-menu",
                  { attrs: { slot: "overlay" }, slot: "overlay" },
                  [
                    _c(
                      "a-menu-item",
                      {
                        key: "1",
                        on: {
                          click: function ($event) {
                            return _vm.rightClick("black")
                          },
                        },
                      },
                      [_vm._v(" 黑色 ")]
                    ),
                    _c(
                      "a-menu-item",
                      {
                        key: "2",
                        on: {
                          click: function ($event) {
                            return _vm.rightClick("#fff")
                          },
                        },
                      },
                      [_vm._v(" 白色 ")]
                    ),
                    _c(
                      "a-menu-item",
                      {
                        key: "3",
                        on: {
                          click: function ($event) {
                            _vm.rightClick("rgb(223,220,221)")
                          },
                        },
                      },
                      [_vm._v(" 灰色 ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            !_vm.isAddLine &&
            !_vm.isAddText &&
            !_vm.isCheckText &&
            !_vm.isAddAnnotation
              ? _c("div", {
                  staticClass: "copy-img-div",
                  style: { top: _vm.scrollTop + "px" },
                  attrs: { contenteditable: true },
                  on: {
                    mouseover: _vm.focusDiv,
                    mouseout: _vm.blurDiv,
                    keydown: function ($event) {
                      $event.stopPropagation()
                      return _vm.imgRightKeyDown.apply(null, arguments)
                    },
                    paste: _vm.imgRightPaste,
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c("errorModal", {
        ref: "errorModal",
        attrs: { dictList: _vm.dictList, selectErrorType: _vm.selectErrorType },
        on: {
          clearSelectErrorType: _vm.clearSelectErrorType,
          changeErrorValue: _vm.changeErrorValue,
          errorChange: _vm.errorChange,
          topCenterMouseover: _vm.topCenterMouseover,
          changeIsDele: _vm.changeIsDele,
          postRejectValue: _vm.postRejectValue,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }